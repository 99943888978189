import { useState } from 'react';
import { Link } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Dropdown from "./NavDropdownMobile";
import { NavLinkMobile } from "./NavDropdownMobile";
import { NavLogo } from './NavHelpers';
import { ColorPalette } from '../../utils/themes';
import HamburgerSVG from '../../assets/mobileNav/hamburger';
import { DonationButton } from "../donation/DonationButton";
import "./SideBar.css";
import SkipLink from './SkipLink';

/**
 * Styling for the topBar
 */
const topBarStyle: React.CSSProperties = {
  display: "flex",
  backgroundColor: ColorPalette.lightGray,
  alignItems: "center",
  justifyContent: "space-between"
}

/**
 * SideBar component:
 * This component handles navigation on mobile. It will be used to navigate between pages.
 */
function SideBar({ name, ...props }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);
  

  return (
    <div role="banner">
      <SkipLink skipTo="#main"></SkipLink>
      <div style={topBarStyle}>
        <Link className="no-underline" to="/">
          <NavLogo />
        </Link>
        <div tabIndex={0} onKeyDown={toggleShow}>
          <HamburgerSVG className="w-[25px] h-[25px] mr-[10px]" onClick={toggleShow} onKeyDown={toggleShow} />
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose} {...props}> {/*responsive design can be removed if glitchy by deleting responsive = "md" */}
        <Offcanvas.Header closeButton className="">
          <NavLogo />
        </Offcanvas.Header>
        <Offcanvas.Body role="navigation">
          <NavLinkMobile to="/" target="" className="py-[8px] px-[12px] no-underline text-black font-bold text-md -z-10">
            Home
          </NavLinkMobile>
          <Dropdown
            items={[
              ["/about", "About us"],
              ["/board", "Board of Directors"],
              ["/contact", "Contact us"],
            ]}
            heading="About"
          />
          
          <Dropdown
            items={[
              ["/target", "Target Areas"],
              ["/chapters", "FLIP National Chapters"],
              ["/initiatives", "Initiatives"],
            ]}
            heading="Impact"
          />
          
          <Dropdown
            items={[
              ["/Partners&Sponsors", "Partners and Sponsors"],
              ["/donate", "Donate"],
            ]}
            heading="Partners"
          />
          
          <Dropdown
            items={[
              ["/news", "News"],
              ["/podcast", "FLIP Side Podcast"],
            ]}
            heading="Media"
          />
          
          <NavLinkMobile to="/donate" target="" className="flex px-[12px] no-underline mt-4">
            <DonationButton />
          </NavLinkMobile>
        
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

/**
 * Menu component:
 * This component is what displays the Sidebar component, and allows it to be called more easily.
 */
function Menu() {
  return (
    <>
      {['end'].map((placement, idx) => (
        <SideBar key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default Menu;