import { SVGProps } from "react";

export const Next = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Next Button"
        xmlns="http://www.w3.org/2000/svg"
        height = "48"
        width = "48"
        viewBox="0 0 48 48">
        <path d="M12 36l17-12-17-12v24zm20-24v24h4V12h-4z"/>
        <path d="M0 0h48v48H0z" fill="none"/>
    </svg>
);
