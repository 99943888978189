import React from "react";

/**
 * Image props:
 */
interface ImageProps {
  src: string;
  alt: string;
  className?: string;
}

/**
 * Image component:
 * This component handles displaying images for the website
 * @param src - Path or url for the image
 * @param alt- Alternative text for the image
 * @param className- Handles styling for the image
 */
const Image: React.FC<ImageProps> = ({ src, alt, className }) => {
  return <img src={src} alt={alt} className={className} />;
};

export default Image;
