import React, {useState} from "react";
import { PageWrapper } from "../components/page_sections/PageWrapper";
import GenDay from "../assets/GenDay2016.jpg";
import Form from 'react-bootstrap/Form';
import PageTitle from "../components/core/PageTitle";
import { StyledPageSection } from "../components/page_sections/StyledPageSection";

/**
 * Contact Form Component:
 * A form used to contact FLIP National
 */
const ContactForm = () => {
  document.title = "Contact Page";
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <div className="mx-8">
      <div className="max-w-5xl mx-auto my-10 p-6 bg-white rounded-lg shadow-md">
        <img
          src= {GenDay}
          alt="Contact"
          className="w-full h-auto object-cover rounded-md mb-4"
        />
        <h2 className="text-2xl font-semibold mb-2">Contact us!</h2>
        <p className="text-left text-gray-700 mb-6">
          If you are interested in learning more, working with us, donating, or if you
          have any questions, please send us a message using the below form.
        </p>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="validationCustom01">
            <Form.Label>Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Name"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email Address"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            controlId="exampleForm.ControlTextarea1"
            className="mb-3"
          >
            <Form.Label>Comment</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="Leave a comment" required/>
          </Form.Group>
          <button type= "submit" className="text-white py-2 px-4 mt-2 rounded-lg transition duration-300 ease-in-out hover:bg-theme_skyBlue hover:animate-pulse bg-theme_purple transition-colors">
            Submit
          </button>
        </Form>
      </div>
    </div>
  );
}

/**
 * Contact component:
 * The Contact page for FLIP National
 */
const Contact: React.FC = () => {
  return (
    <PageWrapper>
      <StyledPageSection section_aria_label= "Page title banner" background_color="rgb(255, 255, 255)">
        <PageTitle text="Contact Page" />
      </StyledPageSection>
      <main id="main" className="mt-3">
        <ContactForm/>
      </main>
    </PageWrapper>
  );
};

export default Contact;
