import React from "react";
import Image from "./Image";
import ColoredButton, { purpleButtonBoardMembers } from "../core/ColoredButton";

/**
 * Board Card props:
 */
interface CardProps {
  src: string,
  alt: string,
  classNameImg?: string,
  name: string,
  title: string,
  shortBio: string,
  longBio: string,
  modalTrueFunction: (text: string, name: string) => void
}

/**
 * Board Card Component:
 * A card that displays information about a Board Member
 * @param src- Path or url to the Board Member's headshot
 * @param alt- Alternative text for the image
 * @param classNameImg- Styling for the image
 * @param name- Board Member's name
 * @param title- Board Member's title
 * @param shortBio- Short Biography for the Board Member
 * @param longBio- Long Biography for the Board Member
 * @param modalTrueFunction- Allows Modal to open and close
 */
const BoardCard: React.FC<CardProps> = ({ 
  src, 
  alt, 
  classNameImg,
  name,
  title,
  shortBio,
  longBio,
  modalTrueFunction
}) => {

  const handleClick = () => {
    modalTrueFunction(longBio, name)
  }

  return (
    <div className="relative w-full mt-4 bg-white rounded-lg drop-shadow-md" role="region" aria-label={name}>
      <div>
        <Image src={src} alt={alt} className={classNameImg}/>
      </div>
      <div className="h-full max-h-96 p-10">
        <h2 className="text-2xl">{name}</h2>
        <p className="text-sm text-slate-500" aria-label="Job Title">{title}</p>
        <p className="h-60 overflow-y-auto text-sm sm:text-base" aria-label="Short Bio">{shortBio}</p>
        <ColoredButton onClick= {handleClick} title={"View Bio"} aria-label="View Bio" className={purpleButtonBoardMembers}></ColoredButton>
      </div>
    </div>
  );
};

export default BoardCard;