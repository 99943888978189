import React, {useState} from 'react'
import { chaptersInfo } from '../utils/chaptersInfo';
import { Link } from 'react-router-dom';
import SearchIcon from '../assets/Search_Icon';

/**
 * Find Chapter:
 * Searches through all of the chapters and returns chapters based on user input
 */
const FindChapter = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState<any[]>([]);

  /** Search handler: matches user input to chapter data*/
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    /** filters chapters by whether their associated state, name, or school name matches user input */
    const search = chaptersInfo.filter(chapter =>
      chapter.state.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) === true ||
      chapter.schoolName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) === true ||
      chapter.chapterName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) === true
    )
    setFilteredData(search)
  };

  /** List Chapters: lists chapters in an understandable format as buttons that link user to each chapter's page*/
  const listChapters = filteredData.map(chapter =>
    <li>
      <p>
        <Link to = {chapter.href} className="max-w-auto h-25 justify-center block no-underline text-black rounded-lg transition duration-300 ease-in-out hover:bg-theme_darkGray bg-gray-200 transition-colors">
          <b>{chapter.chapterName + " "}</b>
          at {chapter.schoolName} in 
          {" " + chapter.state} state
        </Link>
      </p>
    </li>
  );

  return (
    <div>
    <div className= "flex mb-10 h-10 items-center w-100 rounded-md border border-input bg-white pl-3 text-sm ring-offset-background focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-2">
      <SearchIcon className="h-[16px] w-[16px]" />
      <label htmlFor="search-chapters" hidden>Find Chapter</label>
      <input
        type="search"
        id="search-chapters"
        className="search-input"
        onChange={handleSearch}
        placeholder="Find Chapter"
        style={{ width: "410px", height: "30px" }}
      />
    </div>
    <ul>{listChapters}</ul>
    </div>
  );
};

export default FindChapter;