import { useState } from 'react';
import { Next } from "../../assets/audio/NextButton";
import { Prev } from "../../assets/audio/PrevButton";
import ColoredButton, { purpleButton } from '../core/ColoredButton';
/**
 * Author: Rain Jocas
 * Credit: https://www.letsbuildui.dev/articles/building-an-audio-player-with-react-hooks/
 */

/**
 * Plays episodes and displays their corresponding title and synopsis
 * @param tracks- The list of episodes and their corresponding metadata 
 * @returns 
 */
const AudioPlayer = ({ tracks }) => {
    /** handles track index (aka which track user is on) */
    const [trackIndex, setTrackIndex] = useState(0);

    /** Handles information and metadata for each episode */
    const { title, audioSrc, synopsis, showNotes, target } = tracks[trackIndex];

    /**  Handles switching audio tracks to the previous episode */
    const toPrev = () => {
        if (trackIndex - 1 < 0) {
            setTrackIndex(tracks.length - 1);
        } else {
            setTrackIndex(trackIndex - 1);
        }
    }

    /** Handles switching audio tracks to the next episode */
    const toNext = () => {
        if (trackIndex < tracks.length - 1) {
            setTrackIndex(trackIndex + 1);
        } else {
            setTrackIndex(0);
        }
    }

    /** Handles displaying audio control buttons, aka previous, next, pause, play*/
    const AudioControls = ({
        onPrevClick,
        onNextClick,
    }) => (
        <div className="flex flex-row flex-contain object-contain align items-center mx-5">
            <button type="button" aria-label="Previous" onClick={onPrevClick}>
            <Prev />
            </button>
            <div className="my-10"/>
            <div className="max-w-xl">
                <audio controls
                    style={{
                    display: "block",
                    borderRadius: "10px",
                    width: "full",
                    }}>
                    <source src={audioSrc} type="audio/mpeg" />
                </audio>
            </div>
            <div className="my-10"/>
            <button type="button" aria-label="Next" onClick={onNextClick}>
            <Next />
            </button>
        </div>
    );
    
    return (
        <div aria-label="audio-player">
            <h2 className="text-white">{title}</h2>
        <div aria-label="track-info" className="flex flex-col align items-center">
            <div className= "flex flex-row object-contain">
                <AudioControls
                    onPrevClick={toPrev}
                    onNextClick={toNext}
                />
                <br/>
            </div>
            <p className="text-white mx-10">{synopsis}</p>
            <div className="flex flex-row justify-center">
                <ColoredButton title={"Show Notes"} to={showNotes} target={target} className={purpleButton}/>
            </div>
        </div>
    </div>
    );
}

export default AudioPlayer;
