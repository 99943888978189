
import {useState} from "react";
import Checkbox from "../core/Checkbox";
import Button from 'react-bootstrap/Button';
import { termsAndConditions } from "../../utils/termsAndConditions";
import Collapse from 'react-bootstrap/Collapse';
/*
 * Code for displaying and hiding the donation terms for the the billing page 
 */
const DonationTerms = () => {
    const [showTerms, setShowTerms] = useState(false);
  
    const toggleTerms = () => {
      setShowTerms(!showTerms);
    };
    return (
    <div>
        <Collapse in={showTerms}>
        <div id="example-collapse-text" className="mb-3">
            <p style={{whiteSpace: 'pre-wrap'}}>
                {termsAndConditions.terms}
            </p>
        </div>
        </Collapse>
        <div className="mb-3 d-flex ml-auto">
            <Checkbox id="default-checkbox2" label="Agree To Terms?" className="mb-3"/>
            <span className="ml-auto">
            {!showTerms ? (
                <Button onClick={toggleTerms}
                className="bg-skyBlue-500 text-white rounded hover:bg-skyBlue-600"
                aria-controls="example-collapse-text"
                aria-expanded={showTerms}
                >
                Show terms
                </Button>
            ) : (
                <Button onClick={toggleTerms}
                id=""
                className="bg-skyBlue-500 text-white rounded hover:bg-skyBlue-600"
                >
                Hide terms
                </Button>
            )}
            </span>
        </div>
    </div>);
};

export default DonationTerms;