import React from "react";

/**
 * Grid Props:
 */
interface GridProp {
  children: React.ReactNode
}

/**
 * Basic grid component that takes in children, will turn into 1 col for phone, 2 for tablet, and 3 for desktops depending on screen size
 * @param children - Items within the Grid component
 */
const Grid: React.FC<GridProp> = ({children}) => {
  return (
    <div role="grid" className="mb-32 mt-8 mx-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
      {children}
    </div>
  );
};

export default Grid;