import React, {useState} from "react";
import { PageWrapper } from "../components/page_sections/PageWrapper";
import BoardCard from "../components/media/BoardCard";
import BioModal from "../components/media/Modal";
import Grid from "../components/core/Grid";
import { boardMemberBiosInfo } from "../utils/boardMemberBiosInfo";
import PageTitle from "../components/core/PageTitle";
import { StyledPageSection } from "../components/page_sections/StyledPageSection";

/**
 * Board of Directors page:
 * Webpage describing FLIP National's Board of Directors
 */
const Board: React.FC = () => {
  document.title = "Board Page";
  const [showFullBio, setShowFullBio] = useState(false);
  const [modalText, setModalText] = useState("");
  const [nameText, setNameText] = useState("");

  const expandBio = (text,name) => {
    setModalText(text);
    setNameText(name);
    setShowFullBio(true);
  };

  const closeBio = () => {
    setShowFullBio(false);
  };

  return (
    <PageWrapper>
      <StyledPageSection section_aria_label= "Board of Directors" background_color="rgb(255, 255, 255)">
        <PageTitle text="Board of Directors"/>
      </StyledPageSection>
      <main id="main" className="mx-auto max-w-5xl">
          <Grid>
            {boardMemberBiosInfo.map((item) => (
              <BoardCard 
                src={item.boardImage} 
                alt={item.alt} 
                classNameImg="rounded-lg max-h-52 mx-auto"
                name={item.name}
                title={item.title}
                shortBio={item.shortBio}
                longBio={item.longBio}
                modalTrueFunction={expandBio}
              />
            ))}
          </Grid>
          <BioModal name={nameText} show={showFullBio} bio={modalText} onHide={closeBio} />
        </main>
    </PageWrapper>
  );
};

export default Board;
