/**
 * Initiative props:
 */
interface initiativeProps {
    initiativeName: string,
    description: string,
  }

/**
 * Initiative component:
 * Displays one of FLIP National's initiatives
 * @param initiativeName- Name of the initiative
 * @param description- Description of the initiative
 */
const Initiative: React.FC<initiativeProps> = ({ 
    initiativeName,
    description,
}) => {
    return (
        <div className="mx-20 my-10" role="region" aria-label={initiativeName}>
            <h2>{initiativeName}</h2>
            <p>{description}</p>
        </div>  
  );
};

export default Initiative;