/**
 * Text props:
 */
type textProps = {
    className: string;
    text: string;
  };

/**
 * Alternative to <h1> heading which is scalable based on screen size
 * @param className- styling for the text
 * @param text- the text itself 
 */
export const ScalableHeading: React.FC<textProps> = (props) => {
    return (
      <h1 className={props.className} style = {{fontSize: "calc(20px + 2vmin)"}}>{props.text}</h1>
    );
}

/**
 * Alternative to <h2> heading which is scalable based on screen size
 * @param className- styling for the text
 * @param text- the text itself 
 */
export const ScalableHeading2: React.FC<textProps> = (props) => {
    return (
      <h2 className={props.className} style = {{fontSize: "calc(14px + 2vmin)"}}>{props.text}</h2>
    );
}

/**
 * Alternative to <h3> heading which is scalable based on screen size
 * @param className- styling for the text
 * @param text- the text itself 
 */
export const ScalableHeading3: React.FC<textProps> = (props) => {
    return (
      <h3 className={props.className} style = {{fontSize: "calc(8px + 2vmin)"}}>{props.text}</h3>
    );
}

/**
 * Alternative to <p> html paragraph which is scalable based on screen size
 * @param className- styling for the text
 * @param text- the text itself 
 */
export const ScalableParagraph: React.FC<textProps> = (props) => {
    return (
      <p className={props.className} style = {{fontSize: "calc(1.5px + 2vmin)"}}>{props.text}</p>
    );
}

/**
 * Alternative to <li> list item which is scalable based on screen size
 * @param className- styling for the text
 * @param text- the text itself 
 */
export const ScalableList: React.FC<textProps> = (props) => {
    return (
      <li className={props.className} style = {{fontSize: "calc(1.5px + 2vmin)"}}>{props.text}</li>
    );
}