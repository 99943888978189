import React from "react";

/**
 * Button props:
 */
interface ButtonProps {
  title: string;
  to?: string;
  target?: string;
  className: string;
  onClick?: () => void;
  ariaLabel?: string;
}

/**
 * Default "ClassName" or styling for a purple colored button
 */
export const purpleButton =
  "max-w-auto text-white no-underline font-bold py-2 px-4 mt-2 rounded-lg transition duration-300 ease-in-out hover:bg-theme_skyBlue hover:animate-pulse bg-theme_purple transition-colors";

/**
 * Default "ClassName" or styling for a purple colored button
 */
export const purpleButtonBoardMembers =
  "max-w-auto text-white no-underline py-2 px-4 rounded-lg transition duration-300 ease-in-out hover:bg-theme_skyBlue hover:animate-pulse bg-theme_purple";

/**
 * Default "ClassName" or styling for a skyBlue colored button
 */
export const blueButton =
  "max-w-auto text-black no-underline py-2 px-4 mt-2 rounded-lg transition duration-300 ease-in-out hover:bg-theme_blue bg-theme_skyBlue transition-colors";

/**
 * Default "ClassName" or styling for a red colored button
 */
export const redButton =
  "max-w-auto text-white no-underline py-2 px-4 mt-2 rounded-lg transition duration-300 ease-in-out hover:bg-theme_darkRed bg-theme_red transition-colors";

/**
 * Default "ClassName" or styling for a grey colored button
 */
export const greyButton =
  "max-w-auto text-black no-underline px-3 py-2 rounded-md transition duration-300 ease-in-out hover:bg-gray-300 bg-gray-200";

/**
 * ColoredButton Component:
 * A Stylized button that comes in different colors
 * @param title- The button's text
 * @param to- The path or url the button takes you to
 * @param target- Specifies where 'to' opens, either in the same tab or a new one
 * @param className- The styling for the component
 */
const ColoredButton: React.FC<ButtonProps> = (props) => {
  if (props.onClick != null) {
    return (
      <button onClick={props.onClick} className={props.className} aria-label={props.ariaLabel}>
        {props.title}
      </button>
    )
  }
  else return (
    <a className={props.className} href={props.to} target={props.target}>
      {props.title}
    </a>
  );
};

export default ColoredButton;
