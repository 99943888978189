  import React, { useState } from "react";
  import { Link } from "react-router-dom";

  import PlusSVG from "../../assets/mobileNav/plus";
  import MinusSVG from "../../assets/mobileNav/minus";

  /**
   * Dropdown component props:
   */
  type DropdownProps = {
    items: [string, string][];
    heading: string;
  };

  /**
   * Dropdown component:
   * This component is the dropdown menu, where hovering over the heading shows the pages related to the topic
   * @param items - 2D list of route and title pairs for the entries in the dropdown menu
   * @param heading - the heading for the dropdown menu
   */
  const NavDropdown: React.FC<DropdownProps> = ({
    items,
    heading,
  }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const Callback = () => {
      setShowDropdown((prev) => !prev);
    }

    const dropdownIcon = showDropdown ? <MinusSVG className="h-[12px] w-[12px] mr-[4px]"/> : <PlusSVG className="h-[12px] w-[12px] mr-[4px]"/>;

    return (
      <div>
        <div className="flex items-center py-[8px] px-[12px] transition-all delay-75 ease-in-out" onClick={Callback}>
          {dropdownIcon}
          <div className="no-underline text-black font-bold text-md "> 
            {heading} 
          </div>
        </div>
        {showDropdown && (
          <div>
            {items.map(([route, title], i) => (
              <div key={i} className="items-center py-[4px] px-[28px]">
                <NavLinkMobile to={route} target="" className="no-underline text-black font-bold text-md ">
                  {title}
                </NavLinkMobile>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  /**
   * NavLinkMobileProps props:
   */
  type NavLinkMobileProps = {
    to: string;
    target: string;
    children: React.ReactNode;
    className?: string;
  };

  /**
   * NavLinkMobile component:
   * A stylized link, primarily used for navigation purposes
   * @param to- Path or url the link takes the user to
   * @param children- Text or other content the visually indicates the link
   * @param className- Styling for the NavLink
   */
  export const NavLinkMobile: React.FC<NavLinkMobileProps> = ({
    to,
    target,
    children,
    className,
  }) => {
    return (
      <Link
        to={to}
        target={target}
        className={
          className ?? "no-underline text-black font-bold text-md "
        }
      >
        <span>{children}</span>
      </Link>
    );
  };

  export default NavDropdown;