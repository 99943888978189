import React, { useMemo } from "react";

/**
 * Styling for the page section
 */
const sectionStyle: React.CSSProperties = {
  width: "100%",
};

/**
 * Page Section props
 */
type PageSectionProps = {
  children: React.ReactNode[] | React.ReactNode;
  section_aria_label?: string;
  background_alt_text?: string;
  section_background?: string;
  background_style_override?: React.CSSProperties;
  background_color?: React.CSSProperties["backgroundColor"];
  className?: string;
};

/**
 * PageSection component:
 * Each large section of the page will be wrapped in a PageSection component, to ensure that the page is
 * accessible and easy to read, as well as responsive to different screen sizes.
 * @param children - the children of the component
 * @param section_aria_label - the screen reader label for the section
 * @param section_background - the background image source for the section
 * @param background_style_override - any additional styles to apply to the background
 * @param background_color - the background color for the section
 * @param className - the class name for the section (tailwind classes)
 */
export const PageSection: React.FC<PageSectionProps> = ({
  children,
  section_aria_label,
  section_background,
  background_style_override,
  background_color,
  className,
}) => {
  const backgroundStyle = useMemo(
    () => ({
      background: `url(${section_background})`,
      ...sectionStyle,
      ...background_style_override,
    }),
    [background_style_override, section_background]
  );
  return (
    <div role="main" aria-label="main"
      style={{
        backgroundColor: background_color,
      }}
      className={className}
    >
      <section role="main" aria-label={section_aria_label} style={backgroundStyle}>
        {children}
      </section>
    </div>
  );
};
