import React from 'react';

// Define props interface
interface MySVGProps {
    className: string;
}

/**
 * MinusSVG component:
 * This component is the mobile nav dropdown visual and not meant to be tab on
 * @param className - Styling for MinusSVG
 */

const MinusSVG: React.FC<MySVGProps> = ({ className }) => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      fill="none" 
      viewBox="0 0 24 24" 
      stroke-width="1.5" 
      stroke="currentColor" 
      className={className}
    >
      <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
    </svg>
);

export default MinusSVG;
