
import React from "react";
import PageTitle from "../components/core/PageTitle";
import DonateForm from "../components/donation/DonationForm";
import { StyledPageSection } from "../components/page_sections/StyledPageSection";

/**
 * Donate page:
 * Webpage for donating to FLIP National
 */
const Donate: React.FC = () => {
  document.title = "Donate Page";
  return (
    // No navigation bar and no footer because "any other links or calls-to-action on the page can distract from the primary goal."
    <>
      <StyledPageSection section_aria_label= "Donate to FLIP National" background_color="rgb(255, 255, 255)">
        <PageTitle text="Donate to FLIP National"/>
      </StyledPageSection>
      <main id="main" className="mx-8">
        <DonateForm/>
      </main>
    </>
  );
};

export default Donate;
