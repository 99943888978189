import React from "react";
import Form from 'react-bootstrap/Form';

/**
 * Checkbox component props:
 */
interface CheckboxProps {
    id: string;
    label: string;
    className?: string;
}
/**
 * Checkbox component:
 * @param id - id of the textbox to reference
 * @param label - text to go along with the checkbox
 * @param className (optional) - any additional styling 
 */
const Checkbox: React.FC<CheckboxProps> = ({id, label, className}) => {
    return (<div key={label} className={className}>
        <Form.Check // prettier-ignore
          type="checkbox"
          id={id}
          label={label}
          className={className}
        />
    </div>);
};
export default Checkbox;