import React from "react";
import Image from "./Image";

/**
 * Action Card props:
 */
interface CardProps {
  src: string,
  alt: string,
  classNameImg?: string,
  title: string,
  shortDesc: string,
  pin: string,
}

/**
 * Board Card Component:
 * A card that displays information about how FLIP National carries out their mission
 * @param id- Id of the Action Card
 * @param src- Path or url to the image
 * @param alt- Alternative text for the image
 * @param classNameImg- Styling for the image
 * @param title- Card's title
 * @param shortDesc- Short description for the card
 * @param pin - text for pin of the card
 */
const ActionCard: React.FC<CardProps> = ({ 
  src, 
  alt, 
  classNameImg,
  title,
  shortDesc,
  pin,
}) => {

  return (
    <div className="relative w-full h-full bg-white rounded-lg drop-shadow-md" role="region" aria-label={title} >
      {/* Image */}
      <Image src={src} alt={alt} className={classNameImg}/>
      
      {/* Logo */}
      <span aria-label="Logo" className="p-3 rounded-full text-3xl items-center justify-center" style={{backgroundColor: "#3e9dd5"}}>
          {pin}
      </span>
      
      {/* Content */}
      <div className="h-full max-h-96 mt-6 px-4 text-center" >
        <h4>{title}</h4>
          <p className="h-60 overflow-y-auto text-sm sm:text-base">{shortDesc}</p>
      </div>

    </div>
  );
};

export default ActionCard;