import { SVGProps } from "react";

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
   <svg
      role="img"
      aria-label="Search Icon"
      width="20"
      height="20"
      viewBox="0 0 500 500"
      id="svg4162"
      version="1.1"
      >
   <defs id="defs4164" />
   <g
      id="layer1"
      transform="translate(0,-552.36216)">
         <g
            id="g1400"
            transform="translate(-4.3609793,-7.6704785)">
         <path
            id="path4714"
            d="M 232.83952,614.96702 A 154.04816,154.04794 0 0 0 78.79153,769.01382 154.04816,154.04794 0 0 0 232.83952,923.06184 154.04816,154.04794 0 0 0 386.88751,769.01382 154.04816,154.04794 0 0 0 232.83952,614.96702 Z m 0,26.77613 A 129.95832,127.2707 0 0 1 362.79832,769.01382 129.95832,127.2707 0 0 1 232.83952,896.28449 129.95832,127.2707 0 0 1 102.88194,769.01382 129.95832,127.2707 0 0 1 232.83952,641.74315 Z"
            style= {{
               opacity: "1",
               fill: "#2b0000",
               fillOpacity: "1",
               stroke: "none",
               strokeOpacity:"1",}}
         />
         <rect
            ry="18.08342"
            rx="33.249443"
            transform="matrix(0.65316768,0.7572133,-0.60689051,0.79478545,0,0)"
            y="319.55432"
            x="794.8775"
            height="36.16684"
            width="173.02675"
            id="rect4721"
            style= {{
               opacity: "1",
               fill: "#2b0000",
               fillOpacity: "1",
               stroke: "none",
               strokeOpacity:"1",}}
         />
      </g>
   </g>
   </svg>
);

export default SearchIcon;
