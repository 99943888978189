import React from "react";
import { PageWrapper } from "../components/page_sections/PageWrapper";
import { PageSection } from "../components/page_sections/PageSection";
import Carousel from "../components/media/Carousel";
import FindChapter from "../components/FindChapter";
import CountUpAnimation from "../components/media/CountUpAnimation";
import useScreenType from "../utils/useScreenType";
import GenWeek from "../assets/GenWeek2022Brown.png";

/**
 * Count Up Box props:
 */
type countUpBoxProps = {
  initialValue: number;
  targetValue: number;
  description: string;
};

/**
 * Displays a box that counts up from initial value to target value, as well as the statistic it is for
 * @param initialValue- The number the CountUpBox starts on
 * @param targetValue- The number the CountUpBox ends on
 * @param description- A short description of what that ending number is for
 */
const CountUpBox: React.FC<countUpBoxProps> = (props) => {
  return (
    <div className="w-48 h-28 gap-5 px-4 py-4 rounded-lg transition duration-300 ease-in-out hover:bg-theme_purple bg-theme_blue transition-colors">
      <CountUpAnimation
        initialValue={props.initialValue}
        targetValue={props.targetValue}
      ></CountUpAnimation>
      <div>{props.description}</div>
    </div>
  );
};

/**
 * Get Box Class Function
 * @returns a tailwind classname, in order to style the countup boxes differently
 * according to the screen size
 */
const GetBoxClass = (): string => {
  const screenType = useScreenType()
  if (screenType === "desktop" || screenType === "tablet") {
    return ("no-underline text-white font-bold px-5 pt-5");
  }
  else {
    return ("no-underline text-white font-bold px-2 pt-5");
  }
  
}

/**
 * DynamicView Component:
 * Handles the styling differences between desktop/tablet/phone views of the Landing
 */
const DynamicView: React.FC = () => {
  const screenType = useScreenType();
  const description =
    "We promote equal opportunity for first-generation and low-income students by raising" +
    " awareness of the issues facing FGLI students, building sustainable, supportive student led communities and" +
    " advocating for policy changes through national and campus-based chapter initiatives. We also foster" +
    " cross-organizational partnerships to ensure that all students have equal acces to quality education.";
  if (screenType === "desktop") {
    return (
      <div className="flex flex-row p-5 gap-5 align items-center justify-center space-between">
        <img
          src={GenWeek}
          alt="Furman FGSA students celebrating Gen Week"
          className="object-scaledown w-1/2 rounded-lg"
        />
        <div className="flex flex-col w-1/2">
          <h2>What We Do</h2>
          <p className="text-xl">{description}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col p-5 align items-center justify-center space-between">
        <img
          src={GenWeek}
          alt="First-Gens@Brown students celebrating Gen Week 2022"
          className="object-scaledown rounded-lg"
        />
        <h2 className="px-10 pt-10 ">What We Do</h2>
        <p className="px-10 text-xl">{description}</p>
      </div>
    );
  }
};

/**
 * Landing page:
 * Homepage for FLIP National
 */
const LandingPage: React.FC = () => {
  document.title = "Home Page";
  return (
    <PageWrapper>
      <PageSection section_aria_label="main" className="">
        <main id="main">
          <Carousel />
          <div className="flex flex-row flex-wrap pb-5 align items-center justify-center space-between">
            <a href="/initiatives" target="_blank" className={GetBoxClass()}>
              <CountUpBox initialValue={0} targetValue={15000} description={"Students Served"} />
            </a>
            <a href="/initiatives" target="_blank" className={GetBoxClass()}>
              <CountUpBox initialValue={0} targetValue={5000} description={"Microgrants Dispersed"} />
            </a>
            <a href="/target" target="_blank" className={GetBoxClass()}>
              <CountUpBox initialValue={0} targetValue={7} description={"Target Areas"} />
            </a>
            <a href="/chapters" target="_blank" className={GetBoxClass()}>
              <CountUpBox initialValue={0} targetValue={32} description={"Chapters"}/>
            </a>
          </div>
          <h2>Our Mission</h2>
          <p className="px-10 pb-10 text-xl">"FLIP" is an acronym; it stands for First-Generation Low-Income Partnership.  FLIP
            National is a national non-profit organization that does advocacy work on behalf of
            first-generation and/or low-income (FGLI) college students at colleges & universities
            across the country.</p>
          <DynamicView></DynamicView>
          <h2>Who We Are</h2>
          <p className="px-10 pb-10 text-xl">"FLIP" is an acronym; it stands for First-Generation Low-Income Partnership.  FLIP
            National is a national non-profit organization that does advocacy work on behalf of
            first-generation and/or low-income (FGLI) college students at colleges & universities
            across the country.  Our organizational structure is similar to that of Questbridge 
            or fraternities & sororities; that is, there is a national organization with chapters
            at different schools across the country.  Our core tenet is that we center student
            advocacy & the student voice above all else, which is why our chapters are dedicated,
            student-runFGLI student organizations.</p>
          <h3 className="pb-5">Find out if your school is a chapter by searching below:</h3>
          <FindChapter></FindChapter>
        </main>
      </PageSection>
    </PageWrapper>
  );
};

export default LandingPage;
