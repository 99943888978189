import React from "react";
import { socials } from "../../utils/constants";
import MailingListModal from "../mailingList/MailingListModal";
import { purpleButton } from "../core/ColoredButton";
//import { NONAME } from "dns";
import ColoredButton from "../core/ColoredButton";

/**
 * Footer component:
 * A section at the bottom of a page that is uniform across pages. Primarily for contact
 * information and copyright purposes
 */
const Footer = () => {
  return (
    
    <footer role="contentinfo" className="mt-10">
      {/* contentInfo is used to identify common information at the bottom of every page */} 
      {/* Outer div for bg and inner divs for containers then add content*/}
      <div className="w-full bg-gray-200">
        <div className="py-10 px-3 flex justify-center items-center flex-col">
          <div className="mx-auto max-w-5xl">
            <nav className="flex justify-center items-center">
              {socials.map((item) => (
                  <a
                  key={item.id}
                  href={item.url}
                  target= "blank"
                  className="mx-2"
                  >
                    <img
                      src={item.iconUrl}
                      alt={item.title}
                      className="w-12 h-12 p-2 rounded-md transition ease-in-out hover:bg-gray-300" 
                    />
                  </a>
              ))}
            </nav>
          </div>
          
          <div className="w-full mx-auto max-w-lg flex flex-col items-center lg:justify-between lg:flex-row">
            <ColoredButton className={purpleButton} to="/contact" target="_new" title="Contact Us!"></ColoredButton>
            <MailingListModal/>
          </div> 

        </div>
      </div>

      <div className="py-10 w-full bg-gray-300 px-12">
        <div className="mt-10 w-full mx-auto max-w-5xl justify-center items-center">
          <p><i>FLIP National</i> 358 West 51st Street, Apt. BF New York, NY 10019 </p>
          <p> © 2015 All rights reserved. </p>
        </div>
      </div>
    
    </footer>
    
  );
};

export default Footer;