import React, { useState, useRef } from "react";
import DonationModal from "../donation/DonationModal";
import image from "../../assets/GenWeek19@Columbia.jpg";
import ColoredButton, { purpleButton } from "../core/ColoredButton";
import { Overlay, ToggleButton, ToggleButtonGroup, Tooltip } from "react-bootstrap";
import useScreenType from "../../utils/useScreenType";

/**
 * Donation Form:
 * Form that handles Donations for FLIP National,
 * with some default inputs and the ability to choose donation amount
 */
const DonationForm: React.FC = () => {
  const [amount, setAmount] = useState("25.00");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [denomination, setDenomination] = useState(1);
  const [target, setTarget] = useState(null);
  const [tooltipText, setTooltipText] = useState('');
  const customAmountRef = useRef<HTMLInputElement>(null);
  const tooltipTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null); //tooltipTimeoutRef can be of type NodeJs.Timeout or null
  const screenType = useScreenType();

  
  const fee = (((0.029 * parseFloat(amount)) + 0.30).toFixed(2)).toString();
  
  const handleClose = () => setShow(false);

  const handleAmountChange = (event) => {
    const input = event.target.value;
    // input must be a number
    if (/^\d+(\.\d{0,2})?$/.test(input) || input === "") {
      setAmount(input);
      setError("");
    }
  };
  // Change the current amount to the selected denomination 
  const handleSuggestedClick = (suggestedAmount) => {
    setAmount(suggestedAmount.toString());
    setError("");
  };
   // Clear the amount when the user wants to enter a custom amount
  const handleCustomAmountClick = () => {
    // Timeout necessary for rendering 
    setTimeout(() => {
      if (customAmountRef.current) {
        setAmount("");
        setDenomination(5);
        customAmountRef.current.focus(); 
      }
    }, 0); 
  };


  const handleSubmission = () => {
    // Throw error about no amount
    if (amount === "") {
      setError("Please enter a donation amount.");
      return;
    }
    setShow(true);
  };

  const handleMouseEnter = (event, text) => {
    tooltipTimeoutRef.current = setTimeout(() => {
      setTarget(event.target);
      setTooltipText(text);
      setShowTooltip(true);
    }, 250); 
  };

  const handleMouseLeave = () => {
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
    setShowTooltip(false);
  };

  return (
    <div className="max-w-5xl mx-auto my-10 p-6 bg-white rounded-lg shadow-md">
      <img
        src={image}
        alt="Gen Week 2019 at Columbia"
        className="w-full h-auto object-cover rounded-md mb-4"
        style={{ objectPosition: '40% 40%' }}
      />
      <h2 className="text-2xl font-semibold mb-2">Support Our Mission</h2>
      <p className="text-left text-gray-700 mb-6">
        FLIP National is a national nonprofit organization that centers student advocacy and the
        student voice in advocating for first-generation and/or low-income (FGLI) college students
        across the country. FGLI students face many unique obstacles in getting through college, many
        of which affect their ability to complete their education. Your generous contribution to FLIP
        National goes directly towards our efforts to support student efforts to address issues facing
        FGLI students on college campuses. <br /> <br />
        On behalf of all of the FGLI students we serve, thank you for your support!
      </p>
      {/* Donation type */}
      <div className="d-grid gap-2 mt-3 mb-3">
        <ToggleButtonGroup type="radio" name="donation type" defaultValue={1} vertical={screenType === "phone"}>
          <ToggleButton
            variant="outline-primary"
            id="one-radio-1"
            value={1}
            onClick={() => {setAmount("25.00");setDenomination(1)}}
            onMouseEnter={(e) => handleMouseEnter(e, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis maximus eros sit amet condimentum efficitur. Vestibulum condimentum euismod eleifend. Etiam tristique at quam quis lacinia.')}
            onMouseLeave={handleMouseLeave}
          >
            One-time
          </ToggleButton>
          <ToggleButton
            variant="outline-primary"
            id="recurring-radio-2"
            value={2}
            onClick={() => {setAmount("50.00");setDenomination(2)}}
            onMouseEnter={(e) => handleMouseEnter(e, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis maximus eros sit amet condimentum efficitur. Vestibulum condimentum euismod eleifend. Etiam tristique at quam quis lacinia.')}
            onMouseLeave={handleMouseLeave}
          >
            Recurring
          </ToggleButton>
        </ToggleButtonGroup>
        {/* Tooltip for various buttons (e.g., recurring, one-time, denominations, etc.) */}
        <Overlay target={target} show={showTooltip} placement="top" transition={true}>
          {(props) => (
            <Tooltip id="overlay-tooltip" {...props}>
              {tooltipText}
            </Tooltip>
          )}
        </Overlay>
      </div>
      {/* Amount box */}
      <div className="space-y-4">
        <div className="relative bg-gray-200 border rounded-md">
          <span className="absolute bg-gray-200 inset-y-0 left-0 flex items-center pl-2 pr-2">$</span>
          <input
            ref={customAmountRef}
            type="text"
            value={amount}
            onChange={handleAmountChange}
            placeholder="Enter amount"
            className="pl-8 w-full py-2 focus:outline-none"
          />
        </div>
        {error && <p className="text-left text-red-500 text-sm">{error}</p>}
        {/* Amount for donation */}
        <div className="d-grid gap-2 mt-3 mb-3">
          <ToggleButtonGroup type="radio" name="amounts" value={denomination} vertical={screenType === "phone"}>
            <ToggleButton
            onMouseEnter={(e) => handleMouseEnter(e, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}
            onMouseLeave={handleMouseLeave}
            onClick={() => {handleSuggestedClick("25.00"); setDenomination(1)}}
            variant="outline-secondary"
            id="25-radio-1"
            value={1}
            >
              $25
            </ToggleButton>
            <ToggleButton
            onMouseEnter={(e) => handleMouseEnter(e, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}
            onMouseLeave={handleMouseLeave}
            onClick={() => {handleSuggestedClick("50.00"); setDenomination(2)}}
            variant="outline-secondary"
            id="50-radio-1"
            value={2}
            >
              $50
            </ToggleButton>
            <ToggleButton
            onMouseEnter={(e) => handleMouseEnter(e, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}
            onMouseLeave={handleMouseLeave}
            onClick={() => {handleSuggestedClick("100.00"); setDenomination(3)}}
            variant="outline-secondary"
            id="100-radio-1"
            value={3}
            >
              $100
            </ToggleButton>
            <ToggleButton
            onMouseEnter={(e) => handleMouseEnter(e, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}
            onMouseLeave={handleMouseLeave}
            onClick={() => {handleSuggestedClick("250.00"); setDenomination(4)}}
            variant="outline-secondary"
            id="250-radio-1"
            value={4}
            >
              $250
            </ToggleButton>
            <ToggleButton
            onMouseEnter={(e) => handleMouseEnter(e, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}
            onMouseLeave={handleMouseLeave}
            onClick={handleCustomAmountClick}
            variant="outline-secondary"
            id="custom-radio-1"
            value={5}
            >
              Custom amount
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {amount !== "" && <p className="text-left"> Plus an additional ${fee} to cover gateway fees. </p>}
      </div>
      <div className="d-grid gap-2 mt-3">
        <ColoredButton title={"Donate"} onClick={handleSubmission} className={purpleButton}/>
      </div>
      <DonationModal donation={amount} fee={fee} show={show} handleClose={handleClose} />
    </div>
  );
};

export default DonationForm;
