import React from "react";
import { PageWrapper } from "../../components/page_sections/PageWrapper";
import { StyledPageSection} from "../../components/page_sections/StyledPageSection"
import PageTitle from "../../components/core/PageTitle";
import Chapter from "../../components/Chapter";
import { chaptersInfo } from "../../utils/chaptersInfo";

/**
 * Appalachian State page:
 * Webpage for FLIP National's chapter at Appalachian State
 */
const AppalachianState: React.FC = () => {
  document.title = "AppalachianState";
  var chapter = chaptersInfo[0];
  return (
    <PageWrapper>
        <StyledPageSection section_aria_label= "Page title banner"background_color="rgb(255, 255, 255)">
          <PageTitle text={chapter.chapterName} />
        </StyledPageSection>
        <main id="main">
          <Chapter 
            schoolName={chapter.schoolName}
            chapterName={chapter.chapterName}
            chapterEmail= {chapter.chapterEmail as string}
            instagram={chapter.instagram}
            twitter={chapter.twitter}
            facebook={chapter.facebook}
            chapterLogo={chapter.chapterLogo}
            logoAlt={chapter.logoAlt}
            initiatives={chapter.initiatives}
            impact={chapter.impact} 
          />
        </main>
    </PageWrapper>
  );
};


export default AppalachianState;