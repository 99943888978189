import React from "react";

/**
 * DonationButton component props:
 */
type DonationButtonProps = {};

/**
 * DonationButton component style:
 * This is the style for the donation button component.
 */
const donationButtonStyle: React.CSSProperties = {
  fontSize: "20px",
  color: "white",
  borderRadius: "4px",
  borderTopLeftRadius: "0",
  borderBottomLeftRadius: "0",
  fontWeight: "bold",
  cursor: "pointer",
  height: "40px"
};

/**
 * DonationButton component:
 * This is the code where you build the button component.
 */
export const DonationButton: React.FC<DonationButtonProps> = () => {
  return (
    <div
      style={donationButtonStyle}
      aria-label= "Donate"
      className="px-4 py-1 hover:bg-theme_skyBlue hover:animate-pulse bg-theme_purple transition-colors rounded-xl"
    >
      Donate
    </div>
  );
};