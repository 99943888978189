import Appalachian from "../assets/chapters/Appalachian State Logo.jpg"
import scaleProject from "../assets/chapters/The Scale Project Logo.jpg";
import Boston from "../assets/chapters/FLIP @ BostonU Logo.png";
import Brown from "../assets/chapters/FirstGen@Brown Logo.jpg";
import CMU from "../assets/chapters/FirstTogether@CMU Logo.jpg";
import CWRU from "../assets/chapters/First CWRU Logo.jpg";
import Wooster from "../assets/chapters/FSGO - College of Wooster Logo.jpg";
import Columbia from "../assets/chapters/Columbia FLI Network Logo.jpg";
import Cornell from "../assets/chapters/Cornell FGSU Logo.jpg";
import Emory from "../assets/chapters/Emory FLIP Logo.jpg";
import Furman from "../assets/chapters/Furman FGSA Logo.jpg";
import Mason from "../assets/chapters/F1rst Gen Mason Logo.jpg";
import Madison from "../assets/chapters/Madison First Generation Society Logo.jpg";
import Hopkins from "../assets/chapters/JHU FLI Logo .jpg";
import Lehigh from "../assets/chapters/Lehigh F1RST Logo.png";
import NYU from "../assets/chapters/FLIP at NYU Logo.jpg";
import Northeastern from "../assets/chapters/Northeastern FGLI-SU Logo.jpg";
import Pitzer from "../assets/chapters/FirstGen Pitzer College Logo.jpg";
import SRJC from "../assets/chapters/SRJC FLIP Photo.jpg";
import Tarleton from "../assets/chapters/Tarleton F1RST Logo.jpg";
import TXST from "../assets/chapters/FLIP at TXST Logo.png";
import UMass from "../assets/chapters/UMass FLIP Logo.jpg";
import UCLA from "../assets/chapters/AFGU - UCLA Logo.jpg";
import UFlorida from "../assets/chapters/FGO Logo.jpg";
import Michigan from "../assets/chapters/FGCS@Michigan Logo.jpg";
import NewHaven from "../assets/chapters/UNew Haven FGSA Logo.jpg";
import UNCCH from "../assets/chapters/FGSA at UNC Logo.png";
import NorthTexas from "../assets/chapters/FGSO UNT Logo.jpg";
import UPenn from "../assets/chapters/PennFirst Logo.jpg";
import UVA from "../assets/chapters/FLIP at UVA Logo.jpg";
import Vanderbilt from "../assets/chapters/FirstVU Logo.png";
import Yale from "../assets/chapters/YFAM Logo.jpg";

/** Placeholder text */
const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."

/**
 * Provides information for every FLIP National Chapter
 */
export const chaptersInfo = [
    {
      id: "0",
      href: "/AppalachianState",
      schoolName: "Appalachian State",
      chapterName: "First Generation Students Association (FGSA)",
      state: "North Carolina",
      chapterEmail: "firstgenerationstudentclub@gmail.com",
      instagram: "https://www.instagram.com/appstate_fgsa/",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: Appalachian,
      logoAlt: "FGSA logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "1",
      href: "/BardCollege",
      schoolName: "Bard College",
      chapterName: "The Scale Project",
      state: "New York",
      chapterEmail: "scaleprojectbard@gmail.com",
      instagram: "https://www.instagram.com/thescaleproject/",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: scaleProject,
      logoAlt: "The Scale Project logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "2",
      href: "/BostonU",
      schoolName: "Boston University",
      chapterName: "FLIP @ BostonU",
      state: "Massachusetts",
      chapterEmail: "flip@bu.edu",
      instagram: "https://www.instagram.com/flipbostonu/",
      twitter: "",
      facebook: "https://www.facebook.com/flipbostonu/",
      website: "",
      chapterLogo: Boston,
      logoAlt: "FLIP @ BostonU logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "3",
      href: "/Brown",
      schoolName: "Brown University",
      chapterName: "First-Gens@Brown",
      state: "Rhode Island",
      chapterEmail: "first-gens@brown.edu",
      instagram: "https://www.instagram.com/brownfirstgens/",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: Brown,
      logoAlt: "First-Gens@Brown logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "4",
      href: "/CarnegieMellon",
      schoolName: "Carnegie Mellon University",
      chapterName: "FirstTogether",
      state: "Pennslyvania",
      chapterEmail: "firsttogethercmu@gmail.com",
      instagram: "",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: CMU,
      logoAlt: "FirstTogether logo",
      initiatives: loremIpsum,
      impact: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      id: "5",
      href: "/CWRU",
      schoolName: "Case Western Reserve University",
      chapterName: "FirstCWRU",
      state: "Ohio",
      chapterEmail: "1stcwruexec@case.edu",
      instagram: "https://www.instagram.com/firstcwru/",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: CWRU,
      logoAlt: "FirstCWRU logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "6",
      href: "/Wooster",
      schoolName: "College of Wooster",
      chapterName: "First Generation Students Organization (FGSO)",
      state: "Ohio",
      chapterEmail: "fgso@live.wooster.edu",
      instagram: "https://www.instagram.com/woosterfirstgen/?hl=en",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: Wooster,
      logoAlt: "FGSO logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "7",
      href: "/Columbia",
      schoolName: "Columbia University",
      chapterName: "Columbia FLI Network",
      state: "New York",
      chapterEmail: "flinetwork.cu@gmail.com",
      instagram: "https://www.instagram.com/flinetwork_cu/",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: Columbia,
      logoAlt: "Columbia FLI Network logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "8",
      href: "/Cornell",
      schoolName: "Cornell University",
      chapterName: "Cornell FGSU",
      state: "New York",
      chapterEmail: "fgsuatcornell@gmail.com",
      instagram: "https://www.instagram.com/cornellfgsu/",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: Cornell,
      logoAlt: "FGSU logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "9",
      href: "/Emory",
      schoolName: "Emory University",
      chapterName: "Emory FLIP",
      state: "Georgia",
      chapterEmail: "emoryflip@gmail.com",
      instagram: "https://www.instagram.com/emoryflip/",
      twitter: "",
      facebook: "https://m.facebook.com/EmoryFLIP/",
      website: "https://www.notion.so/emoryflip/Homepage-1f7e726c2f524e349690f1794298f4e5",
      chapterLogo: Emory,
      logoAlt: "Emory FLIP logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "10",
      href: "/Furman",
      schoolName: "Furman University",
      chapterName: "Furman First Generation Student Alliance (FGSA)",
      state: "South Carolina",
      chapterEmail: "fgsafurman@gmail.com",
      instagram: "https://www.instagram.com/furmanfgsa/",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: Furman,
      logoAlt: "FGSA logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "11",
      href: "/GeorgeMason",
      schoolName: "George Mason University",
      chapterName: "F1rst Gen @ Mason",
      state: "Virginia",
      chapterEmail: "f1rstgenmason@gmail.com",
      instagram: "https://www.instagram.com/fgm_gmu/",
      twitter: "",
      facebook: "https://m.facebook.com/firstgenmason/",
      website: "",
      chapterLogo: Mason,
      logoAlt: "F1rst Gen @ Mason logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "12",
      href: "/JamesMadison",
      schoolName: "James Madison University",
      chapterName: "Madison First Generation Society",
      state: "Virginia",
      chapterEmail: "mfgsjmu@gmail.com",
      instagram: "https://www.instagram.com/mfgsjmu/",
      twitter: "",
      facebook: "",
      website: "https://beinvolved.jmu.edu/student_community?club_id=52215",
      chapterLogo: Madison,
      logoAlt: "Madison First Generation Society logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "13",
      href: "/JohnsHopkins",
      schoolName: "Johns Hopkins University",
      chapterName: "I'm First at Johns Hopkins",
      state: "Maryland",
      chapterEmail: "imfirst@jhu.edu",
      instagram: "",
      twitter: "",
      facebook: "https://m.facebook.com/firstgenmason/JHUFLI",
      website: "",
      chapterLogo: Hopkins,
      logoAlt: "I'm First at Johns Hopkins logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "14",
      href: "/LehighU",
      schoolName: "Lehigh University",
      chapterName: "Lehigh F1RST",
      state: "Pennsylvania",
      chapterEmail: "inf1rst@lehigh.edu",
      instagram: "https://www.instagram.com/lehigh_f1rst/",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: Lehigh,
      logoAlt: "Lehigh F1RST logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "15",
      href: "/NYU",
      schoolName: "New York University",
      chapterName: "FLIP at NYU",
      state: "New York",
      chapterEmail: "flip.nyu@gmail.com",
      instagram: "https://www.instagram.com/flip_nyu/",
      twitter: "",
      facebook: "https://m.facebook.com/firstgenmason/FLIPatNYU",
      website: "",
      chapterLogo: NYU,
      logoAlt: "FLIP at NYU logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "16",
      href: "/Northeastern",
      schoolName: "Northeastern University",
      chapterName: "First-Gen Low-Income Student Union (FGLI-SU)",
      state: "Massachusetts",
      chapterEmail: "1stgennu@gmail.com",
      instagram: "https://www.instagram.com/northeasternfglisu/",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: Northeastern,
      logoAlt: "FGLI-SU logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "17",
      href: "/Pitzer",
      schoolName: "Pitzer College",
      chapterName: "Pitzer First-Gen Club",
      state: "California",
      chapterEmail: "pzfirstgen@students.pitzer.edu",
      instagram: "https://www.instagram.com/firstgen.pz/",
      twitter: "",
      facebook: "https://www.facebook.com/groups/pitzerfirstgen",
      website: "",
      chapterLogo: Pitzer,
      logoAlt: "Pitzer First-Gen Club logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "18",
      href: "/SRJC",
      schoolName: "Santa Rosa Junior College",
      chapterName: "SRJC FLIP",
      state: "California",
      chapterEmail: "",
      instagram: "",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: SRJC,
      logoAlt: "SRJC FLIP logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "19",
      href: "/TarletonState",
      schoolName: "Tarleton State University",
      chapterName: "Tarleton F1RST",
      state: "Texas",
      chapterEmail: "tarletonf1rst@gmail.com",
      instagram: "https://www.instagram.com/tsuf1rst/",
      twitter: "https://twitter.com/tsuf1rst/status/1287021839424815104",
      facebook: "https://www.facebook.com/tsuf1rst",
      website: "",
      chapterLogo: Tarleton,
      logoAlt: "Tarleton F1RST",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "20",
      href: "/TexasState",
      schoolName: "Texas State University",
      chapterName: "FLIP at TXST",
      state: "Texas",
      chapterEmail: "txstflip@gmail.com",
      instagram: "https://www.instagram.com/txstflip/",
      twitter: "https://twitter.com/txstflip?lang=en",
      facebook: "",
      website: "",
      chapterLogo: TXST,
      logoAlt: "FLIP at TXST logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "21",
      href: "/UMassAmherst",
      schoolName: "UMass Amherst",
      chapterName: "UMass FLIP",
      state: "Massachusetts",
      chapterEmail: "umassflip@gmail.com",
      instagram: "https://www.instagram.com/umass.flip/",
      twitter: "https://twitter.com/flipumass",
      facebook: "https://www.facebook.com/UMassFLIP",
      website: "",
      chapterLogo: UMass,
      logoAlt: "UMass FLIP logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "22",
      href: "/UCLA",
      schoolName: "University of California, Los Angeles",
      chapterName: "Association of First Generation Undergraduates (AFGU)",
      state: "California",
      chapterEmail: "afgu@ucla.edu",
      instagram: "https://www.instagram.com/afgu_ucla/",
      twitter: "",
      facebook: "https://www.facebook.com/afguucla",
      website: "",
      chapterLogo: UCLA,
      logoAlt: "AFGU logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "23",
      href: "/UFlorida",
      schoolName: "University of Florida",
      chapterName: "First Generation Organization (FGO)",
      state: "Florida",
      chapterEmail: "uf.firstgenorg@gmail.com",
      instagram: "https://www.instagram.com/firstgenuf/",
      twitter: "",
      facebook: "https://www.facebook.com/FirstGenerationOrganization",
      website: "",
      chapterLogo: UFlorida,
      logoAlt: "FGO logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "24",
      href: "/UMichigan",
      schoolName: "University of Michigan",
      chapterName: "FGCS@Michigan",
      state: "Michigan",
      chapterEmail: "firstgeneboard@umich.edu",
      instagram: "https://www.instagram.com/umfirstgen/",
      twitter: "https://twitter.com/umfirstgen",
      facebook: "https://www.facebook.com/firstgens",
      website: "",
      chapterLogo: Michigan,
      logoAlt: "FGCS@Michigan logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "25",
      href: "/UNewHaven",
      schoolName: "University of New Haven",
      chapterName: "UNew Haven FGSA",
      state: "Connecticut",
      chapterEmail: "fgsa@newhaven.edu",
      instagram: "",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: NewHaven,
      logoAlt: "FGSA logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "26",
      href: "/UNCCH",
      schoolName: "University of North Carolina at Chapel Hill",
      chapterName: "First Generation Student Association (FGSA)",
      state: "North Carolina",
      chapterEmail: "fgsa.unc@gmail.com",
      instagram: "https://www.instagram.com/uncfirstgens/",
      twitter: "https://twitter.com/uncfirstgens",
      facebook: "https://m.facebook.com/uncfirstgens/",
      website: "https://uncfirstgens.weebly.com/",
      chapterLogo: UNCCH,
      logoAlt: "FGSA logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "27",
      href: "/UNT",
      schoolName: "University of North Texas",
      chapterName: "UNT First Generation Student Organization (FGSO)",
      state: "Texas",
      chapterEmail: "untfgso@gmail.com",
      instagram: "",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: NorthTexas,
      logoAlt: "FGSO logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "28",
      href: "/UPennsylvania",
      schoolName: "University of Pennsylvania",
      chapterName: "Penn First",
      state: "Pennsylvania",
      chapterEmail: "pennfirstboard@gmail.com",
      instagram: "https://www.instagram.com/pennfirstplus/",
      twitter: "https://twitter.com/pennfirstplus?lang=en",
      facebook: "https://m.facebook.com/PennFirst/",
      website: "https://pennfirst.weebly.com/",
      chapterLogo: UPenn,
      logoAlt: "Penn First logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "29",
      href: "/UVirginia",
      schoolName: "University of Virginia",
      chapterName: "FLIP at UVA",
      state: "Virginia",
      chapterEmail: "flipatuva@gmail.com",
      instagram: "https://www.instagram.com/flipatuva/",
      twitter: "",
      facebook: "",
      website: "",
      chapterLogo: UVA,
      logoAlt: "FLIP at UVA logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "30",
      href: "/UVanderbilt",
      schoolName: "Vanderbilt University",
      chapterName: "FirstVU",
      state: "Tennessee",
      chapterEmail: "vandyfirstvu@gmail.com",
      instagram: "https://www.instagram.com/vandyfirstvu/",
      twitter: "",
      facebook: "https://www.facebook.com/FirstVU/",
      website: "",
      chapterLogo: Vanderbilt,
      logoAlt: "FirstVU logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
    {
      id: "31",
      href: "/Yale",
      schoolName: "Yale University",
      chapterName: "Yale FGLI Advocacy Movement (YFAM)",
      state: "Connecticut",
      chapterEmail: "firstgenlowincomeyale@gmail.com",
      instagram: "https://www.instagram.com/yfam.yale/",
      twitter: "",
      facebook: "https://m.facebook.com/firstgenlowincomeyale/",
      website: "",
      chapterLogo: Yale,
      logoAlt: "YFAM logo",
      initiatives: loremIpsum,
      impact: loremIpsum,
    },
  ];

  