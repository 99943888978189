import FoodInsecurity from "../assets/targetAreas/FoodInsecurity.png";
import AcademicDevelopment from "../assets/targetAreas/AcademicDevelopment.png";
import ProfessionalDevelopment from "../assets/targetAreas/ProfessionalDevelopment.png"
import StudentHomelessness from "../assets/targetAreas/StudentHomelessness.jpg"
import FinancialSupport from "../assets/targetAreas/FinancialSupport.png";
import AwarenessVisibility from "../assets/targetAreas/AwarenessVisibility.png";
import WellnessCommunity from "../assets/targetAreas/Wellness&Community.png";

/**
 * Provides information about FLIP National's Target Areas
*/
export const targetAreasInfo = [
    {
        id: "0",
        targetName: "Food Insecurity",
        description: "Food Insecurity continues to be one of the most prevailing problems among first-generation and low-income students in institutions of higher learning. FLIP National wants to expand on already established initiatives and find multi-campus solutions to make food more accessible to students. Even though FLIP has had success with several initiatives on campus such as CU Meal Share and Share Meals, there’s still a lot more to be done to solve the problem of food insecurity among students.",
        src: FoodInsecurity,
        alt: "Short Description: Infographic describing food insecurity in college students. Long Description: There are 3 diagrams depicting what percent of students experience what types of food insecurities. There are discrepancies in these percentages across the diagrams. The goal of the info graphic is to show that college students experience food insecurity.",
    },
    {
        id: "1",
        targetName: "Student Homelessness",
        description: "At various times during the academic year (semester breaks, university holidays, spring break, etc.), some FGLI students face difficulties in finding accommodation if they can’t go home or don’t have the means to secure on-campus housing. As a result, students are unable to take advantage of opportunities such as summer internships, unpaid internships, etc. because they are unable to secure housing and are relegated to relying on people with apartments or hostels for housing. FLIP National seeks to introduce initiatives to improve FGLI students’ access to housing.",
        src: StudentHomelessness,
        alt: "Short Description: Infographic describing homelessness in college students.",
    },
    {
        id: "2",
        targetName: "Academic Development",
        description: "Academic resources are an important component of gaining the adequate knowledge to succeed while in college. FGLI students often have problems affording the books and supplies required for their classes, putting them at a huge disadvantage in terms of keeping up with assignments. FGLI students also do not enjoy the luxury of family support with respect to the academic rigors of a post-secondary education in the same manner as their counterparts whose parents have attended college. FLIP National is dedicated to providing academic support to FGLI students through initiatives like book lending libraries.",
        src: AcademicDevelopment,
        alt: "Short Description: Infographic about the cost of textbooks. Long Description: There are various boxes with information about textbook costs. That information is as follows: '800% rise in textbook costs over the past 30 years', '65% students who have opted not to buy a required textbook due to cost', '$1,200 what the average college student spends per year on books and supplies', '$4.3 billion total earnings on higher education course materials from 1,200 publishers surveyed'",
    },
    {
        id: "3",
        targetName: "Professional Development",
        description: "Many FGLI students do not have the advantage of having parents with a background in the corporate or professional world because their parents are also first-generation or low-income. Partnering with other non-profit organizations to provide professional development services to first-generation and low-income students can help to address these issues on a larger scale. Also, working with non-profit companies to secure in-kind donations, financial support, internship opportunities & networking opportunities will give our FGLI community opportunity & access to both people and companies that are supportive of our mission.",
        src: ProfessionalDevelopment,
        alt: "Short Description: Info graphic about college students entering the work force.",
    },
    {
        id: "4",
        targetName: "Financial Support",
        description: "The need for urgent funding (whether related to travel, personal care, medical needs, academic resources, etc.) can have a huge impact on a student’s academic performance — even prohibiting the student from continuing their education. By providing emergency funds to students in need, FLIP National can help to increase the graduation rates for first-generation and low-income students.",
        src: FinancialSupport,
        alt: "Short Description: Info graphic about student loan debt in america Long description: The total student loan debt outstanding in the United States is $1,011,645,459,648. 37 million americans have student loans. There is a 27% student loan delinquency rate. The average student loan is $23,200",
    },
    {
        id: "5",
        targetName: "Awareness and Visibility",
        description: "For many FGLI students, there is a stigma that may be associated with being an FGLI student that students must learn to cope with. Initiatives such as FLIP’s Class Confessions Analytics, GEN Day & FLIP Voices provide avenues for FGLI students to call attention to the issues that affect them as well as to have their presence acknowledged on their campuses.",
        src: AwarenessVisibility,
        alt: "3 photos from Gen Day stacked on top of eachother.",
    },
    {
        id: "6",
        targetName: "Student Wellness and Community Building",
        description: "Many first-generation and/or low-income students have a hard time adjusting to the culture that exists on college campuses. They may experience “culture shock” because they are entering an environment that is so radically different from the environment they are used to being in. As a result, finding peers that identify as first-generation and/or low-income who can identify with one’s experiences can be very difficult and can have adverse effects on the well-being of an FGLI student. Creating spaces for FGLI students will reduce isolation and build a sense of community.",
        src: WellnessCommunity,
        alt: "3 images stacked on top of eachother that demonstrate the FLIP National community.",
    },
];