import React, {useEffect, useRef} from 'react';

/**
 * Count Up Animation Component:
 * Counts up from one number to another. Takes the same amount of time every time (regardless of values)
 * @param initialValue- The number it begins counting on
 * @param targetValue- The number it ends counting on
 */
const CountUpAnimation = ({initialValue, targetValue}) => {
  const countEl = useRef<HTMLDivElement | null>(null)
  const count = useRef(initialValue);
  /** Handles updating the number being displayed */
  useEffect(() => {
    const interval = setInterval(() => {
      if (count.current < targetValue) {
        const amountPerRun = (targetValue * 5 * (5/10000))
        count.current += amountPerRun
        countEl!.current!.innerText = Math.min(targetValue, Math.floor(count.current)).toString()
        return;
      }
  
      clearInterval(interval)
    }, 5);
      
      return  () => clearInterval(interval)
    }, [targetValue]);

  return (
    <div ref={countEl} />
  );
};




export default CountUpAnimation