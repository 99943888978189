import React from "react";
import { PageWrapper } from "../../components/page_sections/PageWrapper";
import PageTitle from "../../components/core/PageTitle";
import Chapter from "../../components/Chapter";
import { StyledPageSection } from "../../components/page_sections/StyledPageSection";
import { chaptersInfo } from "../../utils/chaptersInfo";

/**
 * University of Pennsylvania page:
 * Webpage for FLIP National's chapter at University of Pennsylvania
 */
const UPennsylvania: React.FC = () => {
  var chapter = chaptersInfo[28];
  document.title = "University of Pennsylvania";
  return (
    <PageWrapper>
      <StyledPageSection section_aria_label= "Page title banner" background_color="rgb(255, 255, 255)">
        <PageTitle text={chapter.chapterName} />
      </StyledPageSection>
      <main id="main">
        <Chapter 
            schoolName={chapter.schoolName}
            chapterName={chapter.chapterName}
            chapterEmail= {chapter.chapterEmail as string}
            instagram={chapter.instagram}
            twitter={chapter.twitter}
            facebook={chapter.facebook}
            chapterLogo={chapter.chapterLogo}
            logoAlt={chapter.logoAlt}
            initiatives={chapter.initiatives}
            impact={chapter.impact} 
        />
      </main>
    </PageWrapper>
  );
};


export default UPennsylvania;