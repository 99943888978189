import { SVGProps } from "react";

export const Prev = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Prev Button"
        xmlns="http://www.w3.org/2000/svg"
        height = "48"
        width = "48"
        viewBox="0 0 48 48">
        <path d="M12 12h4v24h-4zm7 12l17 12V12z"/>
        <path d="M0 0h48v48H0z" fill="none"/>
    </svg>
);
