import React from "react";
//@ts-ignore
import Footer from "./Footer";
import useScreenType from "../../utils/useScreenType";
import Menu from "../navigation/SideBar"
import AccesibleNavBar from "../navigation/AccesibleNavBar"
import SkipLink from "../navigation/SkipLink";

/**
 * PageWrapper component props:
 */
type PageWrapperProps = {
  children: React.ReactNode[] | React.ReactNode;
  isNavTransparent?: boolean;
};

/**
 * PageWrapper component:
 * This component wraps all of the pages in the app. We use this to add features to all pages at once,
 * like having a nav bar, for example.
 * @param children- Page content
 * @param isNavTransparent- Declares whether or not the navigation bar is transparent
 */
export const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  isNavTransparent,
}) => {
  const screenType = useScreenType();
  if (screenType === "phone"){
    return (
      <div tabIndex={0} role="document" >
        <SkipLink skipTo={"#main"}></SkipLink>
        <Menu />
        <div id="page-content" className=" min-h-[100vh]">
          {children}
        </div>
        <Footer />
      </div>
    );
  }
  else {
    return (
      <div tabIndex={0} role="document">
        <SkipLink skipTo={"#main"}></SkipLink>
        <AccesibleNavBar/>
        <div id="page-content" className=" min-h-[100vh]">
          {children}
        </div>
        <Footer />
      </div>
    );
  }
};