import { SVGProps } from "react";

const AmazonMusic = (props: SVGProps<SVGSVGElement>) => (
<svg
   role="img"
   aria-label="Amazon Music Logo"
   xmlns="http://www.w3.org/2000/svg"
   height="52"
   width="90"
   viewBox="0 0 90 52"
   y="0px"
   x="0px"
   id="Layer_1"
   version="1.1">
<defs
   id="defs928" />


<style
   id="style833"
   type="text/css">
	
</style>

<g
   transform="translate(-0.53422889,-18)"
   id="g923">
	
		<linearGradient
   gradientTransform="matrix(90.9945,-90.9945,-90.9945,-90.9945,64008.539,390.498)"
   y2="353.3577"
   x2="-349.23441"
   y1="353.3577"
   x1="-349.85849"
   gradientUnits="userSpaceOnUse"
   id="SVGID_1_">
		<stop
   id="stop835"
   style= {{stopColor: "#0C6CB3"}}
   offset="0" />

		<stop
   id="stop837"
   style= {{stopColor: "#1E84C4"}}
   offset="0.2911" />

		<stop
   id="stop839"
   style= {{stopColor: "#4CC0EF"}}
   offset="0.8873" />

		<stop
   id="stop841"
   style= {{stopColor: "#4CC0EF"}}
   offset="1" />

	</linearGradient>

	<path
   style= {{fill: "url(#SVGID_1_)"}}
   id="path844"
   d="m 59.7,40.5 c -0.6,0.4 -1.5,0.7 -2.6,0.7 -1.7,0 -3.3,-0.2 -4.9,-0.7 -0.4,-0.1 -0.7,-0.2 -0.9,-0.2 -0.3,0 -0.4,0.2 -0.4,0.6 v 1 c 0,0.3 0.1,0.5 0.2,0.7 0.1,0.1 0.3,0.3 0.6,0.4 1.6,0.7 3.4,1 5.4,1 2.1,0 3.7,-0.5 5,-1.5 1.3,-1 1.9,-2.3 1.9,-4 0,-1.2 -0.3,-2.1 -0.9,-2.9 -0.6,-0.7 -1.6,-1.4 -3,-1.9 l -2.8,-1.1 c -1.1,-0.4 -1.9,-0.8 -2.2,-1.2 -0.4,-0.4 -0.6,-0.8 -0.6,-1.5 0,-1.5 1.1,-2.3 3.4,-2.3 1.3,0 2.6,0.2 3.8,0.6 0.4,0.1 0.7,0.2 0.8,0.2 0.3,0 0.5,-0.2 0.5,-0.6 v -1 c 0,-0.3 -0.1,-0.5 -0.2,-0.7 -0.1,-0.2 -0.3,-0.3 -0.6,-0.4 -1.5,-0.5 -3,-0.8 -4.5,-0.8 -1.9,0 -3.5,0.5 -4.7,1.4 -1.2,0.9 -1.8,2.2 -1.8,3.7 0,2.3 1.3,4 3.9,5 l 3,1.1 c 1,0.4 1.6,0.7 2,1.1 0.4,0.4 0.5,0.8 0.5,1.4 0,0.8 -0.3,1.5 -0.9,1.9 z"
   className="st0" />

	
		<linearGradient
   gradientTransform="matrix(90.9945,-90.9945,-90.9945,-90.9945,64008.539,390.498)"
   y2="353.4541"
   x2="-349.23441"
   y1="353.4541"
   x1="-349.85849"
   gradientUnits="userSpaceOnUse"
   id="SVGID_2_">
		<stop
   id="stop846"
   style= {{stopColor: "#0C6CB3"}}
   offset="0" />

		<stop
   id="stop848"
   style= {{stopColor: "#1E84C4"}}
   offset="0.2911" />

		<stop
   id="stop850"
   style= {{stopColor: "#4CC0EF"}}
   offset="0.8873" />

		<stop
   id="stop852"
   style= {{stopColor: "#4CC0EF"}}
   offset="1" />

	</linearGradient>

	<path
   style= {{fill: "url(#SVGID_2_)"}}
   id="path855"
   d="m 44,26.1 v 13.3 c -1.7,1.1 -3.4,1.7 -5.1,1.7 -1.1,0 -1.9,-0.3 -2.4,-0.9 C 36,39.6 35.8,38.7 35.8,37.4 V 26.1 c 0,-0.5 -0.2,-0.7 -0.7,-0.7 H 33 c -0.5,0 -0.7,0.2 -0.7,0.7 v 12.4 c 0,1.7 0.4,3.1 1.3,4 0.9,0.9 2.2,1.4 3.9,1.4 2.3,0 4.6,-0.8 6.8,-2.4 l 0.2,1.2 c 0,0.3 0.1,0.4 0.3,0.5 0.1,0.1 0.3,0.1 0.6,0.1 h 1.5 c 0.5,0 0.7,-0.2 0.7,-0.7 V 26.1 c 0,-0.5 -0.2,-0.7 -0.7,-0.7 h -2.1 c -0.6,0 -0.8,0.3 -0.8,0.7 z"
   className="st1" />

	
		<linearGradient
   gradientTransform="matrix(90.9945,-90.9945,-90.9945,-90.9945,64008.539,390.498)"
   y2="353.59329"
   x2="-349.23441"
   y1="353.59329"
   x1="-349.85849"
   gradientUnits="userSpaceOnUse"
   id="SVGID_3_">
		<stop
   id="stop857"
   style= {{stopColor: "#0C6CB3"}}
   offset="0" />

		<stop
   id="stop859"
   style= {{stopColor: "#1E84C4"}}
   offset="0.2911" />

		<stop
   id="stop861"
   style= {{stopColor: "#4CC0EF"}}
   offset="0.8873" />

		<stop
   id="stop863"
   style= {{stopColor: "#4CC0EF"}}
   offset="1" />

	</linearGradient>

	<path
   style= {{fill: "url(#SVGID_3_)"}}
   id="path866"
   d="m 25,43.4 h 2.1 c 0.5,0 0.7,-0.2 0.7,-0.7 V 30.2 c 0,-1.7 -0.4,-3 -1.3,-3.9 -0.9,-0.9 -2.1,-1.4 -3.8,-1.4 -2.3,0 -4.7,0.8 -7,2.5 -0.8,-1.7 -2.3,-2.5 -4.5,-2.5 -2.2,0 -4.4,0.8 -6.6,2.3 L 4.4,26.1 C 4.4,25.8 4.3,25.7 4.1,25.6 4,25.5 3.8,25.5 3.6,25.5 H 2 c -0.5,0 -0.7,0.2 -0.7,0.7 v 16.6 c 0,0.5 0.2,0.7 0.7,0.7 h 2.1 c 0.5,0 0.7,-0.2 0.7,-0.7 V 29.3 c 1.7,-1 3.4,-1.6 5.2,-1.6 1,0 1.7,0.3 2.1,0.9 0.4,0.6 0.7,1.4 0.7,2.6 v 11.5 c 0,0.5 0.2,0.7 0.7,0.7 h 2.1 c 0.5,0 0.7,-0.2 0.7,-0.7 V 30.4 29.8 c 0,-0.2 0,-0.4 0,-0.5 1.8,-1.1 3.5,-1.6 5.2,-1.6 1,0 1.7,0.3 2.1,0.9 0.4,0.6 0.7,1.4 0.7,2.6 v 11.5 c 0,0.5 0.2,0.7 0.7,0.7 z"
   className="st2" />

	
		<linearGradient
   gradientTransform="matrix(90.9945,-90.9945,-90.9945,-90.9945,64008.539,390.498)"
   y2="353.33289"
   x2="-349.23441"
   y1="353.33289"
   x1="-349.85849"
   gradientUnits="userSpaceOnUse"
   id="SVGID_4_">
		<stop
   id="stop868"
   style= {{stopColor: "#0C6CB3"}}
   offset="0" />

		<stop
   id="stop870"
   style= {{stopColor: "#1E84C4"}}
   offset="0.2911" />

		<stop
   id="stop872"
   style= {{stopColor: "#4CC0EF"}}
   offset="0.8873" />

		<stop
   id="stop874"
   style= {{stopColor: "#4CC0EF"}}
   offset="1" />

	</linearGradient>

	<path
   style= {{fill: "url(#SVGID_4_)"}}
   id="path877"
   d="m 79.5,56.7 c -10.9,4.6 -22.8,6.9 -33.6,6.9 -16,0 -31.5,-4.4 -44,-11.7 -0.2,-0.1 -0.4,-0.2 -0.6,-0.2 -0.7,0 -1.1,0.8 -0.4,1.5 11.6,10.5 27,16.8 44,16.8 12.2,0 26.3,-3.8 36,-11 1.7,-1.2 0.3,-3 -1.4,-2.3 z"
   className="st3" />

	
		<linearGradient
   gradientTransform="matrix(90.9945,-90.9945,-90.9945,-90.9945,64008.539,390.498)"
   y2="353.21909"
   x2="-349.23441"
   y1="353.21909"
   x1="-349.85849"
   gradientUnits="userSpaceOnUse"
   id="SVGID_5_">
		<stop
   id="stop879"
   style={{stopColor:"#0C6CB3"}}
   offset="0" />

		<stop
   id="stop881"
   style={{stopColor:"#1E84C4"}}
   offset="0.2911" />

		<stop
   id="stop883"
   style={{stopColor:"#4CC0EF"}}
   offset="0.8873" />

		<stop
   id="stop885"
   style= {{stopColor: "#4CC0EF"}}
   offset="1" />

	</linearGradient>

	<path
   style={{fill: "url(#SVGID_5_)"}}
   id="path888"
   d="m 79.2,29.4 c 0.9,-1 2.3,-1.5 4.3,-1.5 1,0 2,0.1 2.9,0.4 0.3,0.1 0.4,0.1 0.6,0.1 0.3,0 0.5,-0.2 0.5,-0.7 v -1 C 87.5,26.4 87.4,26.1 87.3,26 87.2,25.9 87,25.7 86.8,25.6 85.5,25.3 84.2,25 83,25 c -2.8,0 -4.9,0.8 -6.5,2.5 -1.5,1.6 -2.3,4 -2.3,7 0,3 0.7,5.3 2.2,6.9 1.5,1.6 3.6,2.4 6.4,2.4 1.5,0 2.9,-0.2 4,-0.7 0.3,-0.1 0.5,-0.2 0.6,-0.4 0.1,-0.1 0.1,-0.4 0.1,-0.7 v -1 c 0,-0.5 -0.2,-0.7 -0.5,-0.7 -0.1,0 -0.3,0 -0.5,0.1 -1.1,0.3 -2.2,0.5 -3.2,0.5 -1.9,0 -3.3,-0.5 -4.2,-1.5 -0.9,-1 -1.3,-2.6 -1.3,-4.7 v -0.5 c 0.1,-2.2 0.5,-3.8 1.4,-4.8 z"
   className="st4" />

	
		<linearGradient
   gradientTransform="matrix(90.9945,-90.9945,-90.9945,-90.9945,64008.539,390.498)"
   y2="353.10449"
   x2="-349.23441"
   y1="353.10449"
   x1="-349.85849"
   gradientUnits="userSpaceOnUse"
   id="SVGID_6_">
		<stop
   id="stop890"
   style= {{stopColor: "#0C6CB3"}}
   offset="0" />

		<stop
   id="stop892"
   style= {{stopColor: "#1E84C4"}}
   offset="0.2911" />

		<stop
   id="stop894"
   style= {{stopColor: "#4CC0EF"}}
   offset="0.8873" />

		<stop
   id="stop896"
   style= {{stopColor: "#4CC0EF"}}
   offset="1" />

	</linearGradient>

	<path
   style= {{fill: "url(#SVGID_6_)"}}
   id="path899"
   d="M 83.7,66.1 C 88.9,61.7 90.3,52.6 89.2,51.2 88.7,50.6 86.3,50 83.3,50 c -3.2,0 -7,0.7 -9.9,2.7 -0.9,0.6 -0.7,1.4 0.2,1.3 3.1,-0.4 10.1,-1.2 11.4,0.4 1.2,1.6 -1.4,8.2 -2.6,11.1 -0.3,0.9 0.4,1.2 1.3,0.6 z"
   className="st5" />

	
		<linearGradient
   gradientTransform="matrix(90.9945,-90.9945,-90.9945,-90.9945,64008.539,390.498)"
   y2="353.2955"
   x2="-349.23441"
   y1="353.2955"
   x1="-349.85849"
   gradientUnits="userSpaceOnUse"
   id="SVGID_7_">
		<stop
   id="stop901"
   style= {{stopColor: "#0C6CB3"}}
   offset="0" />

		<stop
   id="stop903"
   style= {{stopColor: "#1E84C4"}}
   offset="0.2911" />

		<stop
   id="stop905"
   style= {{stopColor: "#4CC0EF"}}
   offset="0.8873" />

		<stop
   id="stop907"
   style= {{stopColor: "#4CC0EF"}}
   offset="1" />

	</linearGradient>

	<path
   style= {{fill: "url(#SVGID_7_)"}}
   id="path910"
   d="m 69.8,25.4 h -2.1 c -0.5,0 -0.7,0.2 -0.7,0.7 v 16.6 c 0,0.5 0.2,0.7 0.7,0.7 h 2.1 c 0.5,0 0.7,-0.2 0.7,-0.7 V 26.1 c 0,-0.4 -0.2,-0.7 -0.7,-0.7 z"
   className="st6" />

	
		<linearGradient
   gradientTransform="matrix(90.9945,-90.9945,-90.9945,-90.9945,64008.539,390.498)"
   y2="353.37411"
   x2="-349.23441"
   y1="353.37411"
   x1="-349.85849"
   gradientUnits="userSpaceOnUse"
   id="SVGID_8_">
		<stop
   id="stop912"
   style= {{stopColor: "#0C6CB3"}}
   offset="0" />

		<stop
   id="stop914"
   style= {{stopColor: "#1E84C4"}}
   offset="0.2911" />

		<stop
   id="stop916"
   style= {{stopColor: "#4CC0EF"}}
   offset="0.8873" />

		<stop
   id="stop918"
   style= {{stopColor:"#4CC0EF"}}
   offset="1" />

	</linearGradient>

	<path
   style= {{fill: "url(#SVGID_8_)"}}
   id="path921"
   d="M 70.4,18.6 C 70,18.2 69.4,18 68.7,18 c -0.7,0 -1.2,0.2 -1.6,0.6 -0.4,0.4 -0.6,0.9 -0.6,1.5 0,0.6 0.2,1.2 0.6,1.5 0.4,0.4 0.9,0.6 1.6,0.6 0.7,0 1.2,-0.2 1.6,-0.6 0.4,-0.4 0.6,-0.9 0.6,-1.5 0,-0.6 -0.1,-1.2 -0.5,-1.5 z"
   className="st7" />

</g>

</svg>
);

export default AmazonMusic