import Accordion from 'react-bootstrap/Accordion';
import ColoredButton from './core/ColoredButton';
import { purpleButton } from './core/ColoredButton';

/**
 * Chapter props:
 */
type chapterProps = {
  src: string;
  collegeName: string;
  chapterName: string;
  chapterEventKey: string;
};

/**
 * Chapter component:
 * This component is a helper method for the chapters accordian. It details a single accordian
 * item which can be used for every chapter
 * @param src - path that accordian item is being linked to
 * @param collegeName - Name of the college
 * @param chapterName - Name of the chapter
 * @param chapterEventKey- Event key for accordian item
 */
export const Chapter: React.FC<chapterProps> = (props) => {
  return (
    <Accordion.Item eventKey={props.chapterEventKey}>
      <Accordion.Header>{props.collegeName}</Accordion.Header>
      <Accordion.Body>
        <div className='max-w-md'>
          <ColoredButton className={purpleButton} title={props.chapterName} to={props.src} target={"_new"}/>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

/**
 * ChaptersAccordian component:
 * This component is an accordian structure that provides links to every chapters page. It is a good
 * alternative to the map on mobile
 */
function ChaptersAccordian() {
  return (
    <Accordion>
      <Chapter chapterEventKey= "1" src={"/AppalachianState"} collegeName={"Appalachian State"} chapterName={"First Generation Students Association (FGSA)"}></Chapter>
      <Chapter chapterEventKey= "2" src={"/BardCollege"} collegeName={"Bard College"} chapterName={"The Scale Project"}></Chapter>
      <Chapter chapterEventKey= "3" src={"/BostonU"} collegeName={"Boston University"} chapterName={"FLIP @ BostonU"}></Chapter>
      <Chapter chapterEventKey= "4" src={"/Brown"} collegeName={"Brown University"} chapterName={"First-Gens@Brown"}></Chapter>
      <Chapter chapterEventKey= "5" src={"/CarnegieMellon"} collegeName={"Carnegie Mellon University"} chapterName={"FirstTogether"}></Chapter>
      <Chapter chapterEventKey= "6" src={"/CWRU"} collegeName={"Case Western Reserve University"} chapterName={"FirstCWRU"}></Chapter>
      <Chapter chapterEventKey= "7" src={"/Wooster"} collegeName={"College of Wooster"} chapterName={"First Generation Students Organization (FGSO)"}></Chapter>
      <Chapter chapterEventKey= "8" src={"/Columbia"} collegeName={"Columbia University"} chapterName={"Columbia FLI Network"}></Chapter>
      <Chapter chapterEventKey= "9" src={"/Cornell"} collegeName={"Cornell University"} chapterName={"Cornell FGSU"}></Chapter>
      <Chapter chapterEventKey= "10" src={"/Emory"} collegeName={"Emory University"} chapterName={"Emory FLIP"}></Chapter>
      <Chapter chapterEventKey= "11" src={"/Furman"} collegeName={"Furman University"} chapterName={"Furman First Generation Student Alliance (FGSA)"}></Chapter>
      <Chapter chapterEventKey= "12" src={"/GeorgeMason"} collegeName={"George Mason University"} chapterName={"F1rst Gen @ Mason"}></Chapter>
      <Chapter chapterEventKey= "13" src={"/JamesMadison"} collegeName={"James Madison University"} chapterName={"Madison First Generation Society"}></Chapter>
      <Chapter chapterEventKey= "14" src={"/JohnsHopkins"} collegeName={"Johns Hopkins University"} chapterName={"I'm First at Johns Hopkins"}></Chapter>
      <Chapter chapterEventKey= "15" src={"/LehighU"} collegeName={"Lehigh University"} chapterName={"Lehigh F1RST"}></Chapter>
      <Chapter chapterEventKey= "16" src={"/NYU"} collegeName={"New York University"} chapterName={"FLIP at NYU"}></Chapter>
      <Chapter chapterEventKey= "17" src={"/Northeastern"} collegeName={"Northeastern University"} chapterName={"First-Gen Low-Income Student Union (FGLI-SU)"}></Chapter>
      <Chapter chapterEventKey= "18" src={"/Pitzer"} collegeName={"Pitzer College"} chapterName={"Pitzer First-Gen Club"}></Chapter>
      <Chapter chapterEventKey= "19" src={"/SRJC"} collegeName={"Santa Rosa Junior College"} chapterName={"SRJC FLIP"}></Chapter>
      <Chapter chapterEventKey= "20" src={"/TarletonState"} collegeName={"Tarleton State University"} chapterName={"Tarleton F1RST"}></Chapter>
      <Chapter chapterEventKey= "21" src={"/TexasState"} collegeName={"Texas State University"} chapterName={"FLIP at TXST"}></Chapter>
      <Chapter chapterEventKey= "22" src={"/UMassAmherst"} collegeName={"UMass Amherst"} chapterName={"UMass FLIP"}></Chapter>
      <Chapter chapterEventKey= "23" src={"/UCLA"} collegeName={"University of California, Los Angeles"} chapterName={"Association of First Generation Undergraduates (AFGU)"}></Chapter>
      <Chapter chapterEventKey= "24" src={"/UFlorida"} collegeName={"University of Florida"} chapterName={"First Generation Organization (FGO)"}></Chapter>
      <Chapter chapterEventKey= "25" src={"/UMichigan"} collegeName={"University of Michigan"} chapterName={"FGCS@Michigan"}></Chapter>
      <Chapter chapterEventKey= "26" src={"/UNewHaven"} collegeName={"University of New Haven"} chapterName={"UNew Haven FGSA"}></Chapter>
      <Chapter chapterEventKey= "27" src={"/UNCCH"} collegeName={"University of North Carolina at Chapel Hill"} chapterName={"First Generation Student Association (FGSA)"}></Chapter>
      <Chapter chapterEventKey= "28" src={"/UNT"} collegeName={"University of North Texas"} chapterName={"UNT First Generation Student Organization (FGSO)"}></Chapter>
      <Chapter chapterEventKey= "29" src={"/UPennsylvania"} collegeName={"University of Pennsylvania"} chapterName={"Penn First"}></Chapter>
      <Chapter chapterEventKey= "30" src={"/UVirginia"} collegeName={"University of Virginia"} chapterName={"FLIP at UVA"}></Chapter>
      <Chapter chapterEventKey= "31" src={"/UVanderbilt"} collegeName={"Vanderbilt University"} chapterName={"FirstVU"}></Chapter>
      <Chapter chapterEventKey= "32" src={"/Yale"} collegeName={"Yale University"} chapterName={"Yale FGLI Advocacy Movement (YFAM)"}></Chapter>
    </Accordion>
  );
}

export default ChaptersAccordian;
