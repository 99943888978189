import React from "react";
import { PageWrapper } from "../components/page_sections/PageWrapper";
import PageTitle from "../components/core/PageTitle";
import { StyledPageSection } from "../components/page_sections/StyledPageSection";

/**
 * Your Impact page:
 * Webpage about our donors and their impact
 */
const YourImpact: React.FC = () => {
  document.title = "Partners and Sponsors";
  return (
    <PageWrapper>
      <StyledPageSection section_aria_label= "Partners and Sponsors" background_color="rgb(255, 255, 255)">
        <PageTitle text="Partners and Sponsors"/>
      </StyledPageSection>
      <main id="main">
        
      </main>
    </PageWrapper>
  );
};

export default YourImpact;