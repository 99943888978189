import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Board from "./pages/Board";
import Initiatives from "./pages/Initiatives";
import TargetAreas from "./pages/TargetAreas";
import FLIPChapters from "./pages/FLIPChapters";
import PartnersSponsors from "./pages/PartnersSponsors";
import News from "./pages/News";
import Podcast from "./pages/Podcast";
import Donate from "./pages/Donate";
import BardCollege from "./pages/chapterPages/BardCollege"
import AppalachianState from "./pages/chapterPages/AppalachianState";
import BostonU from "./pages/chapterPages/BostonU";
import Brown from "./pages/chapterPages/Brown";
import CarnegieMellon from "./pages/chapterPages/CarnegieMellon"
import Columbia from "./pages/chapterPages/Columbia";
import Cornell from "./pages/chapterPages/Cornell";
import CWRU from "./pages/chapterPages/CWRU";
import Emory from "./pages/chapterPages/Emory";
import Furman from "./pages/chapterPages/Furman";
import GeorgeMason from "./pages/chapterPages/GeorgeMason";
import JamesMadison from "./pages/chapterPages/JamesMadison";
import JohnsHopkins from "./pages/chapterPages/JohnsHopkins";
import LehighU from "./pages/chapterPages/LehighUniversity";
import Northeastern from "./pages/chapterPages/Northeastern";
import NYU from "./pages/chapterPages/NYU";
import Pitzer from "./pages/chapterPages/Pitzer";
import SRJC from "./pages/chapterPages/SRJC";
import TarletonState from "./pages/chapterPages/TarletonState";
import TexasState from "./pages/chapterPages/TexasState";
import UCLA from "./pages/chapterPages/UCLA";
import UFlorida from "./pages/chapterPages/UFlorida";
import UMassAmherst from "./pages/chapterPages/UMassAmherst";
import UMichigan from "./pages/chapterPages/UMichigan";
import UNT from "./pages/chapterPages/UNT";
import UNCCH from "./pages/chapterPages/UNCCH";
import UNewHaven from "./pages/chapterPages/UNewHaven";
import UPennsylvania from "./pages/chapterPages/UPennsylvania";
import UVanderbilt from "./pages/chapterPages/UVanderbilt";
import UVirginia from "./pages/chapterPages/UVirginia";
import Wooster from "./pages/chapterPages/Wooster";
import Yale from "./pages/chapterPages/Yale";

/**
 * The router will send different URLs to different pages, and let us link between them all.
 * https://reactrouter.com/en/main/start/tutorial has the documentation for this, and all the features
 * available to use. Check it out if you want to add a page!
 */
const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/board",
    element: <Board />,
  },
  {
    path: "/target",
    element: <TargetAreas />,
  },
  {
    path: "/chapters",
    element: <FLIPChapters />,
  },
  {
    path: "/initiatives",
    element: <Initiatives />,
  },
  {
    path: "/news",
    element: <News />,
  },
  {
    path: "/podcast",
    element: <Podcast />,
  },
  {
    path: "/Partners&Sponsors",
    element: <PartnersSponsors />,
  },
  {
   path: "/donate",
   element: <Donate/>
  },
  {
    path: "/AppalachianState",
    element: <AppalachianState />,
  },
  {
    path: "/BardCollege",
    element: <BardCollege />,
  },
  {
    path: "/BostonU",
    element: <BostonU />,
  },
  {
    path: "/Brown",
    element: <Brown />,
  },
  {
    path: "/CarnegieMellon",
    element: <CarnegieMellon />,
  },
  {
    path: "/Columbia",
    element: <Columbia />,
  },
  {
    path: "/Cornell",
    element: <Cornell />,
  },
  {
    path: "/CWRU",
    element: <CWRU />,
  },
  {
    path: "/Emory",
    element: <Emory />,
  },
  {
    path: "/Furman",
    element: <Furman />,
  },
  {
    path: "/GeorgeMason",
    element: <GeorgeMason />,
  },
  {
    path: "/JamesMadison",
    element: <JamesMadison />,
  },
  {
    path: "/JohnsHopkins",
    element: <JohnsHopkins />,
  },
  {
    path: "/LehighU",
    element: <LehighU />,
  },
  {
    path: "/Northeastern",
    element: <Northeastern />,
  },
  {
    path: "/NYU",
    element: <NYU />,
  },
  {
    path: "/Pitzer",
    element: <Pitzer />,
  },
  {
    path: "/SRJC",
    element: <SRJC />,
  },
  {
    path: "/TarletonState",
    element: <TarletonState />,
  },
  {
    path: "/TexasState",
    element: <TexasState />,
  },
  {
    path: "/UCLA",
    element: <UCLA />,
  },
  {
    path: "/UFlorida",
    element: <UFlorida />,
  },
  {
    path: "/UMassAmherst",
    element: <UMassAmherst />,
  },
  {
    path: "/UMichigan",
    element: <UMichigan />,
  },
  {
    path: "/UNT",
    element: <UNT />,
  },
  {
    path: "/UNCCH",
    element: <UNCCH />,
  },
  {
    path: "/UNewHaven",
    element: <UNewHaven />,
  },
  {
    path: "/UPennsylvania",
    element: <UPennsylvania />,
  },
  {
    path: "/UVanderbilt",
    element: <UVanderbilt />,
  },
  {
    path: "/UVirginia",
    element: <UVirginia />,
  },
  {
    path: "/Wooster",
    element: <Wooster />,
  },
  {
    path: "/Yale",
    element: <Yale />,
  },
  
  
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
