import Image from "./media/Image";
import { ScalableHeading2, ScalableParagraph } from "./core/ScalableText";

/**
 * Target Area props:
 */
interface targetAreaProps {
    targetName: string,
    description: string,
    src: string,
    alt: string,
  }

/**
 * Right Target Area component:
 * A target area with the image to the right
 * @param targetName- Name of the target area
 * @param description- Description of the target area
 */
export const RightTargetArea: React.FC<targetAreaProps> = ({ 
    targetName,
    description,
    src,
    alt,
}) => {
    return (
        <section className="flex items-center justify-top mt-20" aria-label={targetName}>
            <div className="w-1/2 self-start mx-10 object-scale-down">
                <ScalableHeading2 className={""} text={targetName}></ScalableHeading2>
                <ScalableParagraph className={""} text={description}></ScalableParagraph>
            </div>
            <div className="w-1/2 flex justify-center mx-10 object-scale-down">
                <Image src={src} alt={alt} className="max-h-120 w-auto rounded-lg shadow-md"></Image>
            </div>
        </section>
  );
};


/**
 * Left Target Area component:
 * A target area with the image to the left
 * @param targetName- Name of the target area
 * @param description- Description of the target area
 */
export const LeftTargetArea: React.FC<targetAreaProps> = ({ 
    targetName,
    description,
    src,
    alt,
}) => {
    return (
        <section className="flex items-center justify-top mt-20" aria-label={targetName}>
            <div className="w-1/2 flex justify-center mx-10 object-scale-down">
                <Image src={src} alt={alt} className="max-h-120 w-auto rounded-lg shadow-md"></Image>
            </div>
            <div className="w-1/2 self-start mx-10 object-scale-down">
                <ScalableHeading2 className={""} text={targetName}></ScalableHeading2>
                <ScalableParagraph className={""} text={description}></ScalableParagraph>
            </div>
        </section>
  );
};


/**
 * Mobile Target Area component:
 * A target area for mobile
 * @param targetName- Name of the target area
 * @param description- Description of the target area
 */
export const MobileTargetArea: React.FC<targetAreaProps> = ({ 
    targetName,
    description,
    src,
    alt,
}) => {
    return ( 
        <section className="justify-top mt-20" aria-label={targetName}>
            <div className="self-start mx-10 object-scale-down">
                <h2>{targetName}</h2>
                <p>{description}</p>
            </div>
            <div className="flex justify-center mx-10 object-scale-down">
                <Image src={src} alt={alt} className="max-h-120 w-auto rounded-lg shadow-md"></Image>
            </div>
        </section>
  );
};

//<Image src={src} alt={alt} className="rounded-md mx-10"></Image>