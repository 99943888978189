import COFLANpdf from "../assets/COFLANFellowshipOverview.pdf";
//import { ColorPalette } from "./themes";
//import { Link } from "react-router-dom";
import ColoredButton, { purpleButton } from "../components/core/ColoredButton";

//Initiatives: COFLAN, fellowship, GENweek, laptop lending library, micro grants, etc…

/**
 * Placeholder text
 */
const LoremIpsum = (
  <i>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
  </i>
)

/**
 * Provides information about the FLIP National Fellowship
*/
const FellowshipDescription = (
  <div>
    <div className="flex flex-col justify-center">
      <p>The FLIP National Fellowship is a program that was started in 2018 to provide first-generation and/or low-income (FGLI) students the opportunity to work for FLIP National doing the work of the organization, advocate for themselves, and develop marketable skills that are transferable to other career fields that students are interested in.  We are currently seeking funding to expand the fellowship and be able to pay stipends to FLIP National fellows</p>
      <div className="align-self-center">
        <ColoredButton className={purpleButton} title="Apply Now" to="https://docs.google.com/forms/d/e/1FAIpQLSd29PIQKoDGdgY1AFapbUG-L9ge3v9_0lXMa11kk-Kl4I9E4Q/viewform" target="_blank"/>
      </div>
    </div>
  </div>
)

/**
 * Provides information about COFLAN
*/
const CoflanDescription = (
  <div>
    <h6>The COFLAN Fellowship is a program that seeks to help FLI alumni start a FLI alumni network at their alma mater. COFLAN is accepting applications for fellows for the 2024-2025 cycle. The fellowship will focus on four main areas:</h6>
      <p className="text-left"><b>Network Establishment: </b>
        COFLAN will support fellows in establishing robust first-generation/low-income (FLI) alumni networks at their respective alma maters, providing them with guidance on effective models and strategies for network development.</p>
      <p className="text-left"><b>Capacity Building: </b>
        COFLAN will equip fellows with the necessary resources, skills, connections, and knowledge to effectively engage with their school administrations and stakeholders, enabling them to advocate for the needs and interests of FLI alumni within their institutions.</p>
      <p className="text-left"><b>Mentorship and Support: </b>
        COFLAN will provide fellows with mentorship opportunities from experienced leaders who have successfully established FLI alumni networks, offering guidance, advice, and support throughout the network-building process.</p>
      <p className="text-left"><b>Community Expansion: </b>
        Foster the growth and expansion of the COFLAN network by cultivating a community and cohort of FLI alumni leaders who are committed to supporting each other and collaborating on initiatives aimed at advancing the interests of FLI students and alumni across various institutions.</p>
      <h5>Timeline: </h5>
        <p><b>Application Deadline: </b>July 31st, 2024</p>
        <p><b>Fellows will be notified by: </b>August 15th, 2024</p>
        <p><b>Fellowship begins: </b>September 18th, 2024</p>
    <div className="flex flex-row justify-center">
      <ColoredButton className={purpleButton} title="Learn More" to={COFLANpdf} target="_blank"/>
      <div className= "min-w-16"/>    
      <ColoredButton className={purpleButton} title="Apply Now" to = "https://forms.gle/huDp15APRd8EPJ98A" target="_blank"/> 
     </div>
  </div>
)

/**
 * Provides information about FLIP National's initiatives
*/
export const initiativesInfo = [
    {
        id: "0",
        initiativeName: "FLIP National Fellowship",
        description: FellowshipDescription,
    },
    {
        id: "1",
        initiativeName: "GEN Week",
        description: "GEN Week is an international week of visibility, awareness of & pride in the first-generation and/or low-income (FGLI) student identity.  GEN Week was started as GEN Day in 2016 to declare, display & embrace the FGLI identity, to demonstrate the presence of FGLI students on the campuses of participating schools, and to acknowledge & celebrate the extent to which FGLI students contribute to campus communities & constitute the fabric of college campus communities.",
    },
    {
        id: "2",
        initiativeName: "Laptop Lending Library",
        description: LoremIpsum,
    },
    {
        id: "3",
        initiativeName: "Microgrants",
        description: LoremIpsum,
    },
    {
        id: "4",
        initiativeName: "COFLAN Fellowship",
        description: CoflanDescription
    },
];