import React from "react";

/**
 * Page Title Props:
 */
interface PageTitleProps {
  text: string,
}
/**
 * Page Title Component:
 * Handles the Title of the page
 * @param text - text for the page title 
 */
const PageTitle: React.FC<PageTitleProps> = ({text}) => {
  return (
    <h1 className="italic text-2xl font-black text-white text-right pr-10 md:text-5xl" >{text}</h1>
  )
};

export default PageTitle;