import React, { ReactElement } from "react";
import { PageWrapper } from "../components/page_sections/PageWrapper";
import PageTitle from "../components/core/PageTitle";
import { StyledPageSection } from "../components/page_sections/StyledPageSection";
import Grid from "../components/core/Grid";
import ActionCard from "../components/media/ActionCard";
import genDay from "../assets/RedGenDayjpg.jpg";
import mascotImage from "../assets/gw.jpg";
import graduationImage from "../assets/vision.jpg";
import books from "../assets/textbookLibrary.jpg";
import students from "../assets/lehighStudents.jpg";
import Groceries from "../assets/Groceries.jpg";
import advocacy from "../assets/advocacy.jpg";
import { ScalableHeading2, ScalableHeading3, ScalableParagraph, ScalableList } from "../components/core/ScalableText";
import { ColorPalette } from "../utils/themes";

/**
 * Styling for a purple sidebubble
 */
export const purpleBubble = "flex left-0 w-1/2 h-96 rounded-l-full transform translate-x-0.5 bg-theme_lightPurple";

/**
 * Styling for a blue sidebubble
 */
export const blueBubble = "flex left-0 w-1/2 h-96 rounded-r-full transform -translate-x-0.5 bg-theme_skyBlue";

/**
 * Bubble props:
 */
type bubbleProps = {
  description: ReactElement;
  bubbleClass: string;
};

/**
 * Side Bubble component:
 * Displays a colored ovular that juts off to one side, and contains information inside
 * @param description
 * @param props 
 */
export const SideBubble: React.FC<bubbleProps> = (props) => {
  return (
    <> 
      <div className= {props.bubbleClass}
        style={{
          backgroundSize: "contain",
          paddingTop: "20px",
          paddingBottom: "20px",
          flexDirection: "row",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          marginTop: "20px"
        }}>
        <div className="absolute inset-0 flex flex-col justify-center items-center text-center p-6">
          {props.description}
        </div>
      </div> 
    </>
  );
}

/**
 * Description of FLIP National's mission statement
 */
const missionStatement = (
  <div className="absolute inset-0 flex flex-col shrink justify-center items-center text-left p-6">
    <ScalableHeading2 className="text-3xl font-bold mb-4"
      text = "FLIP National is">
    </ScalableHeading2>
    <ScalableParagraph className= "text-base"
      text = {"a 501(c)(3) nonprofit established in 2015 to promote equal opportunity for first-generation and low-income students in institutions of higher learning. We aim to establish campus-based chapters and promote collaboration among institutions in addressing issues that first-generation and low-income students face."}>
    </ScalableParagraph>
  </div>
)

/**
 * Description of FLIP National's target areas through a numbered list
 */
const targetAreas = (
  <div>
    <ScalableHeading2 className={"text-3xl font-bold mb-4"}
      text = "Our Target Areas:">
    </ScalableHeading2>
    <ul className="list-none text-left ml-6">
    <ScalableList className={"before:content-['🎯'] before:mr-2"} text="Food Insecurity"></ScalableList>
    <ScalableList className={"before:content-['🎯'] before:mr-2"} text="Student Homelessness"></ScalableList>
    <ScalableList className={"before:content-['🎯'] before:mr-2"} text="Academic Development"></ScalableList>
    <ScalableList className={"before:content-['🎯'] before:mr-2"} text="Professional Development"></ScalableList>
    <ScalableList className={"before:content-['🎯'] before:mr-2"} text="Financial Support"></ScalableList>
    <ScalableList className={"before:content-['🎯'] before:mr-2"} text="Awareness and Visibility"></ScalableList>
    <ScalableList className={"before:content-['🎯'] before:mr-2"} text="Student Wellness & Community Building"></ScalableList>
    </ul>
  </div>
);

/**
 * Description of FLIP National's vision
 */
const vision = (
  <div className="absolute inset-0 flex flex-col justify-center items-center text-left p-6">
    <ScalableHeading2 className="text-3xl font-bold mb-4" text="OUR VISION"></ScalableHeading2>
    <ScalableParagraph className="text-base"
      text = "Our vision is to see a world of post-secondary education where first-generation and low-income students have all the adequate resources and support to fulfill their academic potential and achieve their career ambitions.">
    </ScalableParagraph>
  </div>
)

/**
 * Description of FLIP National's mission (not the same as mission statement)
 */
const ourMission = (
  <div className="absolute inset-0 flex flex-col justify-center items-center text-right p-6">
    <ScalableHeading2 className="text-3xl font-bold mb-4" text = "OUR MISSION"></ScalableHeading2>
    <ScalableParagraph className="text-base"
      text="Our mission is to promote equal opportunity for first-generation and low-income students in institutions of higher learning.">
    </ScalableParagraph>
  </div>
)

/**
 * About page:
 * Webpage detailing FLIP National's mission statement and who we are
 */
const About: React.FC = () => {
  document.title = "About Page";
  return (
      <PageWrapper>
          <StyledPageSection section_aria_label= "About Us" background_color="rgb(255, 255, 255)">
            <PageTitle text="About Us"/>
          </StyledPageSection>
        <main id="main">

        {/* Who Are We? */}
          <section className="flex items-center justify-between mt-20" aria-label="Who We Are">
            <SideBubble description={missionStatement} bubbleClass={blueBubble}/>
            <div className="w-1/2 flex justify-center object-scale-down">
              <img src={genDay}
                alt="8 people in red FLIP National Gen Day t-shirts"
                className="max-h-80 rounded-lg shadow-md" />
            </div>     
          </section>
        
          {/* Target Areas */}
          <section className="flex items-center justify-between mt-20" aria-label="Target Areas">
            <div className="w-1/2 flex justify-center object-scale-down">
                <img src= {Groceries}
                alt="Various groceries and some basic cleaning supplies. There is a handwritten note that reads: 'Thank you for my healthy groceries! Erin G.'"
                className="max-h-80 rounded-lg shadow-md" />
            </div>
            <SideBubble description={targetAreas} bubbleClass={purpleBubble}/>
          </section>

          {/* Our Vision */}
          <section className="flex items-center justify-between mt-20" aria-label= "Our Vision">
            <SideBubble description={vision} bubbleClass={blueBubble}/>
            <div className="w-1/2 flex justify-center object-scale-down">
              <img src={graduationImage}
              alt="Several students from the class of 2017 wearing blue graduation robes and caps"
              className="max-h-80 w-auto rounded-lg shadow-md" />
            </div>     
          </section>

          {/* Our Mission */}
          <section className="flex items-center justify-between mt-20" aria-label="Our Mission">
            <div className="w-1/2 flex justify-center object-scale-down">
                <img src={mascotImage}
                alt="Three people and one GW mascot holding a sign that reads 'I am a Gen+ of change because I pledge to support and empower my FGLI Family! :) #myFGLIFamily'"
                className="max-h-80 w-auto rounded-lg shadow-md" />
            </div>
            <SideBubble description={ourMission} bubbleClass={purpleBubble}/>
          </section>

          {/* Our Mission in Action */}
          <section className="flex justify-center mt-20 mb-20 mx-auto max-w-5xl" aria-label="Mision in Action">
            <div className="relative mx-10 h-auto bg-theme_skyBlue rounded-lg"
              style={{
                backgroundColor: ColorPalette.skyBlue,
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
              }}>
              <div className="flex flex-col justify-center items-center text-center mt-4">
                <ScalableHeading2 className="font-bold" text="Mission in Action"></ScalableHeading2>
                <ScalableHeading3 className="" text="We act on our mission in the the following ways:"></ScalableHeading3>
              </div>
              <div className="mx-auto max-w-5xl">
                <Grid>
                  <ActionCard src={students} alt={"A group of Lehigh students in front of the LOVE statue"} classNameImg="rounded-t-lg max-h-52 mx-auto w-full" title="Community Building" shortDesc={"We strive to physical and virtual communities for first-generation and/or low-income students, alumni/ae, faculty, and administrators within and across institutions of higher learning."} pin={"🏠"}/>
                  <ActionCard src={books} alt={"Library shelf full of books"} classNameImg="rounded-t-lg max-h-52 mx-auto w-full" title="Resource Allocation" shortDesc={"We are establishing and/or expanding chapter and national initiatives to alleviate the stress factors that hamper the academic success and well-being of first-generation and/or low-income students in institutions of higher earning."} pin={"📖"} />
                  <ActionCard src={advocacy} alt={"Person holding a whiteboard that reads: '#myFGLIdentity I advocate for the increased voices + resources for first-gen and low-income students.'"} classNameImg="rounded-t-lg max-h-52 mx-auto w-full" title="Advocacy" shortDesc={"We advocate for policy changes to support first-generation and/or low-income students through our chapters at various institutions across the country, and on the national and international levels."} pin={"📢"}/>
                </Grid>
              </div>
            </div>
          </section>
        </main>
      </PageWrapper>
  );
};

export default About;
