import React from "react";
import { Link } from "react-router-dom";
import DropDownMenu from "./AccesibleDropdown";
import { NavLink, NavLogo } from "./NavHelpers";
import { DonationButton } from "../donation/DonationButton";

/**
 * Accesible Nav Bar Props :
 */
type AccesibleNavBarProps = {
  useAbsolutePositioning?: boolean;
};

/**
 * Accesible Nav Bar component:
 * This component is the navigation bar at the top of every page. It will be used to navigate between pages.
 */
export const AccesibleNavBar: React.FC<AccesibleNavBarProps> = (props) => {
    return (
    <nav className="flex items-center sticky top-0 py-2 z-20 bg-transparent backdrop-filter backdrop-blur-lg bg-opacity-30">
    <Link className="no-underline" to="/">
      <NavLogo />
    </Link>
    <div style={{ flex: 1 }} />
    <nav className="flex items-center bg-slate-100 mr-4 overflow-hidden rounded-md" role="navigation" >
      <Link style = {{fontSize: "16px", color: "black", fontWeight: "bold", textDecoration: "none",}}
        className="flex py-[8px] px-[16px] hover:bg-gray-300 transition-colors" to={"/"}>
          Home
      </Link>
      <DropDownMenu
        items={[
          ["/about", "About us"],
          ["/board", "Board of Directors"],
          ["/contact", "Contact us"],
        ]}
        heading="About"
      />
      <DropDownMenu
        items={[
          ["/target", "Target Areas"],
          ["/chapters", "FLIP National Chapters"],
          ["/initiatives", "Initiatives"],
        ]}
        heading="Impact"
      />
      <DropDownMenu
        items={[
          ["/Partners&Sponsors", "Partners and Sponsors"],
          ["/donate", "Donate"],
        ]}
        heading="Partners"
      />
      <DropDownMenu
        items={[
          ["/news", "News"],
          ["/podcast", "FLIP Side Podcast"],
        ]}
        heading="Media"
      />
      <NavLink to="/donate" target="_blank" className="transition-colors">
        {" "}
        <DonationButton />{" "}
      </NavLink>
    </nav>
  </nav>
);
};

export default AccesibleNavBar
