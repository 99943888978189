import Modal from 'react-bootstrap/Modal';
import ColoredButton, { blueButton} from '../core/ColoredButton';

/**
 * BioModal component:
 * A modal which displays the biography of a person
 * @param show- Handles showing the modal
 * @param onHide- Handles hiding the modal
 * @param bio- The biography being shown by the modal
 * @param name- The name of the Biographee
 */
function BioModal({show, onHide, bio, name}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id={name}>
          {name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-sm sm:text-base">{bio}</p>
      </Modal.Body>
      <Modal.Footer>
        <ColoredButton title={"Close"} onClick={onHide} className={blueButton}aria-label="Close"></ColoredButton>
      </Modal.Footer>
    </Modal>
  );
}

export default BioModal;