import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import targetAreas from "../../assets/gw.jpg";
import CloseButton from "react-bootstrap/CloseButton";
import ColoredButton, { purpleButton } from "../core/ColoredButton";
import "../mailingList/custom-modal-style.css";

/**
 * Modal that pops up urging people to join FLIP National's mailing list,
 * and allowing them to do so
 */
const MailingListModal: React.FC = () => {
  const [show, setShow] = useState(false);
  const [timedPopup, setTimedPopup] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleClose = () => {
    setShow(false);
    setTimedPopup(false);
  };
  const handleShow = () => setShow(true);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formId = "YOUR_HUBSPOT_FORM_ID"; // Placeholder until replaced with actual form ID
    const portalId = "YOUR_HUBSPOT_PORTAL_ID"; // Placeholder until replaced with actual portal ID
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    const payload = {
      fields: [
        { name: "email", value: email },
        { name: "firstname", value: name },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert("Form submitted successfully!");
        handleClose();
      } else {
        alert("Error submitting form.");
      }
    } catch (error) {
      alert("Error submitting form.");
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!show) {setTimedPopup(true);}
  //   }, 9000);
  // }, []);

  return (
    <>
      <ColoredButton
        title="Join Our Mailing List"
        onClick={handleShow}
        className={purpleButton}
        aria-label="Open Mail List Modal"
      />
      <Modal
        aria-label="Form to join Mailing List"
        show={show || timedPopup}
        onHide={handleClose}
        centered
        dialogClassName="custom-modal-style"
      >
        <Modal.Body className="p-0">
          <CloseButton
            className="position-absolute top-0 end-0 m-2"
            onClick={handleClose}
            aria-label="Close"
          />
          <Row className="m-0">
            <Col md={6} className="p-0">
              <img
                src={targetAreas}
                alt="Mailing list"
                className="w-full h-full"
              />
            </Col>
            <Col md={6} className="p-5">
              <h1 className="text-center" tabIndex={-1}>
                Join our Mailing List!
              </h1>
              <p className="text-lg text-center">
                Stay up to date with FLIP National by signing up for our mailing
                list.
              </p>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label className="text-lg">Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    autoFocus
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label className="text-lg">Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <div className="d-grid gap-2">
                  <button
                    type="submit"
                    aria-label="submit"
                    className={purpleButton}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MailingListModal;
