import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/FLIP National Logo (Transparent Background).png";
import { ColorPalette } from "../../utils/themes";

/**
 * Styles each navPill
 */
export const navPillStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  backgroundColor: ColorPalette.lightGray,
};

/**
 * Styling for the NavLink:
 */
const navLinkStyle: React.CSSProperties = {
  // Set the size, color, and padding of the links
  fontSize: "14px",
  color: "black",
  fontWeight: "bold",
  textDecoration: "none",
  // Make the links centered vertically with the nav bar
  display: "flex",
};

/**
 * NavLink props:
 */
type NavLinkProps = {
  to: string;
  target: string;
  children: React.ReactNode;
  className?: string;
};

/**
 * Navlink component:
 * A stylized link, primarily used for navigation purposes
 * @param to- Path or url the link takes the user to
 * @param children- Text or other content the visually indicates the link
 * @param className- Styling for the NavLink
 */
export const NavLink: React.FC<NavLinkProps> = ({
  to,
  target,
  children,
  className,
}) => {
  return (
    <Link
      to={to}
      target={target}
      style={navLinkStyle}
      className={
        className ?? "py-[8px] px-[16px] hover:bg-gray-300 transition-colors"
      }
    >
      <span>{children}</span>
    </Link>
  );
};

/**
 * Styles the text portion of the NavLogo:
 */
const logoTextStyle: React.CSSProperties = {
  fontSize: "26px",
  fontWeight: "bold",
};

/**
 * Styles the website's logo:
 */
const logoStyle: React.CSSProperties = {
  height: "55px",
  marginRight: "4px",
};

/**
 * NavLogo component:
 * Handles displaying the logo used for navigation
 */
export const NavLogo: React.FC = () => {
  return (
    <div
      style={{ marginLeft: "10px" }}
      className="flex flex-row align items-center rounded-2xl bg-white"
    >
      <img src={logo} alt="logo" style={logoStyle} />
      <div style={logoTextStyle}></div>
    </div>
  );
};
