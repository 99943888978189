import React from "react";
import { PageWrapper } from "../components/page_sections/PageWrapper";
import PageTitle from "../components/core/PageTitle";
import { StyledPageSection } from "../components/page_sections/StyledPageSection";
import { targetAreasInfo } from "../utils/targetAreasInfo";
import {LeftTargetArea, MobileTargetArea, RightTargetArea} from "../components/TargetArea";
import useScreenType from "../utils/useScreenType";

const FoodInsecurity = targetAreasInfo["0"];
const StudentHomelessness = targetAreasInfo["1"];
const AcademicDevelopment = targetAreasInfo["2"];
const ProfessionalDevelopment = targetAreasInfo["3"];
const FinancialSupport = targetAreasInfo["4"];
const AwarenessVisibility = targetAreasInfo["5"];
const WellnessCommunity = targetAreasInfo["6"];

/**
 * Target Areas page:
 * Webpage detailing FLIP National's target areas
 */
const TargetAreas: React.FC = () => {
  document.title = "Target Areas";
  const screenType = useScreenType();
  if (screenType === "phone") {
    return (
      <PageWrapper>
        <StyledPageSection section_aria_label= "Page title banner" background_color="rgb(255, 255, 255)">
          <PageTitle text="Our Target Areas" />
        </StyledPageSection>
        <main id="main">
        {targetAreasInfo.map((item) => (
          <MobileTargetArea targetName= {item.targetName} description={item.description} src={item.src} alt={item.alt} />
        ))}
        </main>
      </PageWrapper>
    );
  }
  else {
    return (
      <PageWrapper>
        <StyledPageSection section_aria_label= "Our Target Areas" background_color="rgb(255, 255, 255)">
          <PageTitle text="Our Target Areas" />
        </StyledPageSection>
        <main id="main">
          <LeftTargetArea targetName={FoodInsecurity.targetName} description={FoodInsecurity.description} src={FoodInsecurity.src} alt={FoodInsecurity.alt}/>
          <RightTargetArea targetName={StudentHomelessness.targetName} description={StudentHomelessness.description} src={StudentHomelessness.src} alt={StudentHomelessness.alt}/>
          <LeftTargetArea targetName={AcademicDevelopment.targetName} description={AcademicDevelopment.description} src={AcademicDevelopment.src} alt={AcademicDevelopment.alt}/>
          <RightTargetArea targetName={ProfessionalDevelopment.targetName} description={ProfessionalDevelopment.description} src={ProfessionalDevelopment.src} alt={ProfessionalDevelopment.alt}/>
          <LeftTargetArea targetName={FinancialSupport.targetName} description={FinancialSupport.description} src={FinancialSupport.src} alt={FinancialSupport.alt}/>
          <RightTargetArea targetName={AwarenessVisibility.targetName} description={AwarenessVisibility.description} src={AwarenessVisibility.src} alt={AwarenessVisibility.alt}/>
          <LeftTargetArea targetName={WellnessCommunity.targetName} description={WellnessCommunity.description} src={WellnessCommunity.src} alt={WellnessCommunity.alt}/>
        </main>
      </PageWrapper>
    );
  }
};

export default TargetAreas;