import React, {useState} from "react"; 
import Modal from 'react-bootstrap/Modal';
import DonationInformation from "./DonationInformation";
import DonationTotal from "../donation/DonationTotal";
import Button from 'react-bootstrap/Button';
import DonationTerms from "./DonationTerms";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import useScreenType from "../../utils/useScreenType";


// import {loadStripe} from "@stripe/stripe-js";
// import { Elements, PaymentElement} from "@stripe/react-stripe-js";

/**
 * Donation Modal Props:
 */
interface DonationModalProps {
  show: boolean;
  handleClose: () => void;
  donation: string;
  fee: string;
}

/**
 * Render Content:
 * Shows the selected payment method, including offline donations, stripe, and paypal
 */
const RenderContent = ({show, handleClose, donation, fee, paymentMethod}) => {
  switch (paymentMethod) {
    case 'offline': return (<OfflineDonation show={show} handleClose={handleClose} donation={donation} fee={fee}/>);
    case 'stripe': return (<StripeDonation show={show} handleClose={handleClose} donation={donation} fee={fee}/>);
    case 'paypal': return (<PayPalDonation show={show} handleClose={handleClose} donation={donation} fee={fee}/>);
  }
};

/**
 * Offline Donation Instructions:
 * Instructions for donating to FLIP National offline
 */
const OfflineDonationInstructions = () => {
  return (<div>
    <p className="text-left">
      In order to make an offline donation we ask that you please follow these instructions:
      <br/> <br/>
      Make a check payable to "National First-Generation Low Income Partnership Inc."
      On the memo line of the check, please indicate that the donation is for "FLIP National".
      Please mail your check to:
      <br/> <br/>
      <em>
        FLIP National <br/>
        358 West 51st Street, Apt. BF <br/>
        New York, NY 10019 <br/> <br/>
      </em>
      All contributions will be gratefully acknowledged and are tax deductible.
    </p>
  </div>);
};

/**
 * Offline Donation:
 * Displays information for offline donations
 */
const OfflineDonation: React.FC<DonationModalProps> = ({show, handleClose, donation, fee}) => {
  const totalAmount = (parseFloat(donation) + parseFloat(fee)).toFixed(2);

  return (<div aria-label= "Donation form">
        <Modal.Header>
          <Modal.Title>Personal Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DonationInformation/>
          <OfflineDonationInstructions/>
          <DonationTerms/>
          <DonationTotal donation={donation} fee={fee} totalAmount={totalAmount}/>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <Button variant="primary" type="submit">
            Donate now!
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </div>
  );
};

/**
 * Stripe Content:
 * Displays information for donating with Stripe
 */
const StripeContent = () => {
  return (<div>
    <Modal.Header>
      <Modal.Title>
        Credit Card Information
      </Modal.Title>
      <Modal.Body>
      </Modal.Body>
    </Modal.Header>
  </div>);
};

/**
 * Stripe Donation:
 * Handles donations with Stripe
 */
const StripeDonation: React.FC<DonationModalProps> = ({show, handleClose, donation, fee}) => {
  const totalAmount = (parseFloat(donation) + parseFloat(fee)).toFixed(2);
  // const handleDonation = async () => {
  //   const stripe = await loadStripe("");
  // };
  return (<div>
        <Modal.Header>
          <Modal.Title>Personal Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DonationInformation/>
        </Modal.Body>
        <StripeContent/>
        <Modal.Body>
          <DonationTerms/>
          <DonationTotal donation={donation} fee={fee} totalAmount={totalAmount}/>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <Button variant="primary" type="submit">
            Donate now!
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </div>
  );
};

/**
 * PayPal Donation
 * Handles donations with PayPal
 */
const PayPalDonation: React.FC<DonationModalProps> = ({show, handleClose, donation, fee}) => {
  const totalAmount = (parseFloat(donation) + parseFloat(fee)).toFixed(2);
  return (<div>
        <Modal.Header>
          <Modal.Title>Personal Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DonationInformation/>
          <DonationTerms/>
          <DonationTotal donation={donation} fee={fee} totalAmount={totalAmount}/>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <Button variant="primary" type="submit">
            Donate now!
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </div>
  );
};

/**
 * Donation Modal:
 * Modal that pops up on donation page, allowing you to donate via offline, PayPal, or Stripe
 */
const DonationModal: React.FC<DonationModalProps> = ({show, handleClose, donation, fee}) => {
  const screenType = useScreenType();
  const [paymentMethod, setPaymentMethod] = useState('stripe');
  return (
    <>
      <Modal size='lg' show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <div tabIndex={-1}>
            <Modal.Title>Select Payment Method</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-grid gap-2 mt-3">
            <ToggleButtonGroup type="radio" name="Payment method" defaultValue={2} vertical={screenType === "phone"}>
              <ToggleButton variant="outline-primary" id="offline-radio-1" value={1} onChange={() => {setPaymentMethod('offline')}}>
                Offline Donation
              </ToggleButton>
              <ToggleButton variant="outline-primary" id="stripe-radio-2" value={2} onChange={() => {setPaymentMethod('stripe')}}>
                Stripe - Card
              </ToggleButton>
              <ToggleButton variant="outline-primary" id="paypal-radio-3" value={3} onChange={() => {setPaymentMethod('paypal')}}>
                Paypal
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Modal.Body>     
        <RenderContent paymentMethod={paymentMethod} handleClose={handleClose} donation={donation} fee={fee} show={show}/>   
      </Modal>
    </>
  );
}

export default DonationModal;

