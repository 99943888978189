// @ts-ignore
// eslint-disable-next-line
import email from "../assets/socials/email.svg";
// @ts-ignore
import facebook from "../assets/socials/facebook.svg";
// @ts-ignore
import instagram from "../assets/socials/instagram.svg";
// @ts-ignore
import linkedin from "../assets/socials/linkedin.svg";

/**
 * List of FLIP National's social media accounts
 */
export const socials = [
  {
    id: "0",
    title: "Linkedin",
    iconUrl: linkedin,
    url: "https://www.linkedin.com/company/flip-national/",
  },
  {
    id: "1",
    title: "FaceBook",
    iconUrl: facebook,
    url: "https://www.facebook.com/flipnationalorg/",
  },
  {
    id: "2",
    title: "Instagram",
    iconUrl: instagram,
    url: "https://www.instagram.com/flipnationalorg/?hl=en",
  },
];
