import { SVGProps } from "react";

/**
 * Instagram Component:
 * React SVG of the instagram logo
 */
const Instagram = (props: SVGProps<SVGSVGElement>) => (
    <svg
        aria-label="Instagram Logo"
        width="50"
        height="50"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_702_32812)">
            <path d="M8.99749 11.7147C10.4965 11.7147 11.7118 10.4995 11.7118 9.00042C11.7118 7.50136 10.4965 6.28613 8.99749 6.28613C7.49843 6.28613 6.2832 7.50136 6.2832 9.00042C6.2832 10.4995 7.49843 11.7147 8.99749 11.7147Z" fill="#757185"/>
            <path d="M12.3905 0.857422H5.60479C2.98618 0.857422 0.855469 2.98882 0.855469 5.6081V12.3938C0.855469 15.0124 2.98686 17.1431 5.60615 17.1431H12.3919C15.0105 17.1431 17.1412 15.0117 17.1412 12.3925V5.60674C17.1412 2.98814 15.0098 0.857422 12.3905 0.857422ZM8.99833 13.0717C6.75293 13.0717 4.9269 11.2457 4.9269 9.00028C4.9269 6.75489 6.75293 4.92885 8.99833 4.92885C11.2437 4.92885 13.0698 6.75489 13.0698 9.00028C13.0698 11.2457 11.2437 13.0717 8.99833 13.0717ZM13.7483 4.92885C13.3738 4.92885 13.0698 4.62485 13.0698 4.25028C13.0698 3.87571 13.3738 3.57171 13.7483 3.57171C14.1229 3.57171 14.4269 3.87571 14.4269 4.25028C14.4269 4.62485 14.1229 4.92885 13.7483 4.92885Z" fill="black"/>
        </g>
        <defs>
        <clipPath id="clip0_702_32812">
            <rect width="17.1429" height="17.1429" fill="white" transform="translate(0.427734 0.428711)"/>
        </clipPath>
        </defs>
    </svg>
);

export default Instagram;
