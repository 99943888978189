import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Obama from "../../assets/Obama.png";
import GenWeekBrown from "../../assets/GenWeek2022Brown.png";
import GenWeekFurman from "../../assets/GenDay2022_Furman.jpg";
import Image from "./Image";

/**
 * CarouselItem props:
 */
type CarouselItemProps = {
  src: string;
  title: string;
  text: string;
  alt: string;
};

/**
 * CarouselItem component:
 * This component is for a single carousel item, displaying the image attatched
 * information the carousel component cycles through
 * @param src - Path or url for the image
 * @param title - Heading text in the caption of the carousel item
 * @param text - Caption for carousel item
 * @param alt- Alternative text for the image
 */
const CarouselItem: React.FC<CarouselItemProps> = (props) => {
  return (
    <div>
      <Image src={props.src} alt={props.alt} className="object-cover" />
      <Carousel.Caption>
        <h3>{props.title}</h3>
        <p>{props.text}</p>
      </Carousel.Caption>
    </div>
  );
};

/**
 * Controlled Carousel component:
 * This component allows the user to slide through set images,
 * as well as sliding through automatically
 */
function ControlledCarousel() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      role="tablist"
      aria-label="Sliding Carousel"
    >
      <Carousel.Item role="tab" aria-label="Slide one">
        <div role="tabpanel">
          <CarouselItem
            src={Obama}
            title={"Advocacy"}
            text={
              "FLIP National Co-founder, Mandeep Singh talking about FLIP National with President Obama at the first Obama Foundation Summit"
            }
            alt={
              "FLIP National Co-founder, Mandeep Singh talking about FLIP National with President Obama at the first Obama Foundation Summit"
            }
          ></CarouselItem>
        </div>
      </Carousel.Item>
      <Carousel.Item role="tab" aria-label="Slide two">
        <div role="tabpanel">
          <CarouselItem
            src={GenWeekFurman}
            title={"Gen Week"}
            text={"Furman FGSA students celebrating Gen Week 2022"}
            alt={"Furman FGSA students celebrating Gen Week 2022"}
          ></CarouselItem>
        </div>
      </Carousel.Item>
      <Carousel.Item role="tab" aria-label="Slide three">
        <div role="tabpanel">
          <CarouselItem
            src={GenWeekBrown}
            title={"Gen Week"}
            text={"First-Gens@Brown students celebrating Gen Week 2022"}
            alt={"First-Gens@Brown students celebrating Gen Week 2022"}
          ></CarouselItem>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;
