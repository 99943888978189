import React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Checkbox from "../core/Checkbox";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//@ts-ignore
import QuestionMarkIcon from "../../assets/question-circle.svg"

// DonationInformation contains all the starter code that goes on all the pages of the billing page 
// (i.e., personal info, email, etc.)
/**
 * Donation Information:
 * Handles billing information, including personal info, email, etc.
 */
const DonationInformation = () => {
  return (
    <div>
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridFirstName">
            <Form.Label>
              <div className="flex items-center">
                <span>First Name</span>
                <span className="text-red-600 ml-1">*</span>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-firstname">First Name is used to personalize your donation record.</Tooltip>}
                >
                  <span className="ml-1 cursor-pointer"><img alt="question mark" src={QuestionMarkIcon} /></span>
                </OverlayTrigger>
              </div>
            </Form.Label>
            <Form.Control type="name" placeholder="First Name" autoFocus required />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridLastName">
            <Form.Label>
              <div className="flex items-center">
                <span>Last Name</span>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-lastname">Last Name is used to personalize your donation record.</Tooltip>}
                >
                  <span className="ml-1 cursor-pointer"><img alt="question mark" src={QuestionMarkIcon} /></span>
                </OverlayTrigger>
              </div>
            </Form.Label>
            <Form.Control type="name" placeholder="Last Name" />
          </Form.Group>
        </Row>
        <div key="radio2" className="mb-2">
          <p> Is this donation on behalf of a company?</p>
          <Form.Check
            inline
            label="Yes"
            name="group2"
            type="radio"
            id="inline-radio-1'"
          />
          <Form.Check
            inline
            label="No"
            name="group2"
            type="radio"
            id="inline-radio-2'"
            defaultChecked
          />
        </div>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
          <Form.Label>
            <div className="flex items-center">
              <span>Email Address</span>
              <span className="text-red-600 ml-1">*</span>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-email">We will send the donation receipt to this address.</Tooltip>}
              >
                <span className="ml-1 cursor-pointer"><img alt="question mark" src={QuestionMarkIcon} /></span>
              </OverlayTrigger>
            </div>
          </Form.Label>
          <Form.Control type="email" placeholder="Email Address" required />
        </Form.Group>
        <div className="flex items-center ml-auto mb-3">
          <Checkbox id="default-checkbox" label="Make this an anonymous donation." />
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-email">Would you like to prevent your name, image, and comment from being displayed publicly </Tooltip>}
          >
            <span className="ml-1 cursor-pointer"><img alt="question mark" src={QuestionMarkIcon} /></span>
          </OverlayTrigger>
        </div>
        <Form.Group controlId="exampleForm.ControlTextarea1" className="mb-3">
          <Form.Label>
            <div className="flex items-center">
              <span>Comment</span>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-comment">Would you like to add a comment to this donation?</Tooltip>}
              >
                <span className="ml-1 cursor-pointer"><img alt="question mark" src={QuestionMarkIcon} /></span>
              </OverlayTrigger>
            </div>
          </Form.Label>
          <Form.Control as="textarea" rows={3} placeholder="Leave a comment" />
        </Form.Group>
      </Form>
    </div>
  );
};

export default DonationInformation;