export const episodes = [
    {
        id: "0",
        title: "Welcome to the FLIP Side",
        audioSrc: "https://sphinx.acast.com/p/open/s/61558a5204fbba0013110d22/e/6155b8dc8d6e810013218a7c/media.mp3",
        synopsis: "So what is 'The FLIP Side'? In this introductory episode, we introduce ourselves, what we plan to do with the podcast, and hear a little about why this episode's co-hosts are part of FLIP National.",
        showNotes: "",
        target: "",
    },
    {   
        id: "1",
        title: "Mental Health During a Pandemic",
        audioSrc: "https://sphinx.acast.com/p/open/s/61558a5204fbba0013110d22/e/6172d37bdff6cd001411ad7d/media.mp3",
        synopsis: "The global pandemic turned higher education on its head in 2020.  Ever since, students (FGLI students in particular) have really struggled to adjust to being in college in a COVID-19 environment, which has taken its toll on FGLI students’ mental health.  In this episode of The FLIP Side, we take a deep dive into how we have managed our mental health as FGLI students in college, particularly during the pandemic.",
        showNotes: "",
        target: "",
    },
    {
        id: "2",
        title: "New Season, Same Demands",
        audioSrc: "https://sphinx.acast.com/p/open/s/61558a5204fbba0013110d22/e/62627c4b6cc91500140e071b/media.mp3",
        synopsis: "It's a new season. Spring is in the air, the weather is starting to break, but the only thing that's changing for FGLI students is the weather. Everything else (particularly the conversations that are happening around FGLI student issues) is the same. FGLI students are still experiencing all the same issues that they have been for quite some time, and if you let institutions tell it, they're doing a terrific job of supporting their students. In this episode, we do what institutions don't do: talk to actual FGLI students. We talk with student leaders of one of FLIP National's chapters, FGSA at UNC, about whether or not they feel supported by the institution as students as well as what actionable demands they have of their institutions. We also explore how institutions claim the mantle of supporting FGLI students without actually following through on that claim, and how student leaders can be a part of the solution.",
        showNotes: "",
        target: "",
    },
]