import React from "react";
import { PageWrapper } from "../components/page_sections/PageWrapper";
import PageTitle from "../components/core/PageTitle";
import { StyledPageSection } from "../components/page_sections/StyledPageSection";
import Initiative from "../components/Initiative";
import {initiativesInfo} from "../utils/initiativesInfo";

/**
 * Initiatives page:
 * Webpage describing FLIP National's initiatives
 */
const Initiatives: React.FC = () => {
  document.title = "Initiatives";
  return (
    <PageWrapper>
      <StyledPageSection section_aria_label= "Our Initiatives" background_color="rgb(255, 255, 255)">
        <PageTitle text="Our Initiatives" />
      </StyledPageSection>
      <main id="main" className="my-10">
        {initiativesInfo.map((props: any) => (
          <Initiative
            initiativeName={props.initiativeName}
            description={props.description}>
          </Initiative>
        ))} 
      </main>
    </PageWrapper>
  );
};

export default Initiatives;