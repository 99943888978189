import React from "react";
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';

/**
 * DonationTotal component props:
 */
interface DonationTotalProps {
    donation: string;
    fee: string;
    totalAmount: string;
  }

/**
 * DonationTotal component:
 * @param donation - the amount to be donated
 * @param fee - the fee incurred for using Stripe/paypal
 * @param totalAmount - donation + fee
 */
const DonationTotal: React.FC<DonationTotalProps> = ({donation,totalAmount,fee}) => {
    return (<div>
        <h5>
            <Form.Text> Donation Total: </Form.Text> <Badge bg="primary">${totalAmount}</Badge>
        </h5>
        <Form.Text className="text-left" id="feeInformation" muted>
            ${donation} donation plus ${fee} to help cover fees.
        </Form.Text>
        </div>);
}

export default DonationTotal;