import React, { useState } from "react";
import { NavLink } from "./NavHelpers";

/**
 * Dropdown component:
 * Handles what the dropdown menu displays
 */
const DropDown = ({ items }) => (
  <div tabIndex={-1}>
    {items.map(([route, title], i) => (
      <div
        className="last:rounded-b-md first:rounded-t-md overflow-hidden bg-white"
        role="menuitem"
      >
        <NavLink
          to={route}
          target={title === "Donate" ? "_blank" : ""}
          className="px-4 py-2 hover:bg-gray-100"
        >
          {" "}
          {title}{" "}
        </NavLink>
      </div>
    ))}
  </div>
);

/**
 * Menu Props :
 */
type menuProps = {
  items: [string, string][];
  heading: string;
};

/**
 * Accesible Dropdown Menu Component:
 * Handles opening and closing the dropdown menu
 * @param items- Links/buttons that the dropdown menu displays
 * @param heading- The name/heading of the dropdown menu
 */
const DropDownMenu: React.FC<menuProps> = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <nav
      tabIndex={0}
      aria-label="Dropdown Menu"
      onTouchStart={() => setOpen((open) => true)}
      onTouchEnd={() => setOpen((open) => false)}
      onMouseEnter={() => setOpen((open) => true)}
      onMouseLeave={() => setOpen((open) => false)}
      onClick={() => setOpen((open) => !open)}
      className=""
    >
      <button className="px-3 py-2 flex text-md font-bold hover:bg-gray-300 focus-visible:ring transition-colors">
        {props.heading}
      </button>
      <div className="absolute shadow-sm rounded-md z-50 overflow-hidden transition-all duration-300">
        {open && <DropDown items={props.items} />}
      </div>
    </nav>
  );
};

export default DropDownMenu;
