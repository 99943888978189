/**
 * Color Pallet Component:
 * Allows for universal colors across the entire website
 */
export const ColorPalette = {
  skyBlue: "#8ED1FC",
  logoBlue: "#369FCF",
  blue: "#3E9DD7",
  blueGrey: "#577588",
  darkBlue: "#5242FF",
  purple: "#4E598C",
  lightGray: "#F5F5F5",
  darkGray: "#0005",
  lightPurple: "#B8B8F3",

};
